import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";

/* USER DEFINED COMPONENTS */
import Testimonials from "components/testimonials";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ICONS */
import { BsFillPatchCheckFill } from "react-icons/bs";

/* CONFIG */
import { animation, containerAnimation } from "data/animation";

/* DATA */
import { featureSegregation } from "data/features-details";

const FeaturesPage = () => {
  /* FEATURE BOX */
  const FeatureBox = (props) => {
    return props.index % 2 === 0 ? (
      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-10 xl:gap-20 lg:gap-20 items-center">
        <div className="col-span-1 order-2 xl:order-1 lg:order-1">
          <h2 className="text-2xl xl:text-3xl lg:text-3xl font-helvetica-bold text-black tracking-tight">
            {props.title}
          </h2>
          <p className="text-lg font-proxima-regular text-grey mt-5">
            {props.description}
          </p>
          {props.flatList ? (
            <div className="mt-5">
              <p className="text-lg font-proxima-regular text-grey">
                {props.listHeader}
              </p>
              <div className="flex items-center flex-wrap gap-8 gap-y-5 mt-5">
                {props.list.map((data) => (
                  <div className="flex items-center gap-3" key={data.id}>
                    <BsFillPatchCheckFill className="w-6 h-6 text-logo" />
                    <p className="text-lg font-proxima-semibold text-black">
                      {data.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="mt-5">
              <p className="text-lg font-proxima-regular text-grey">
                {props.listHeader}
              </p>
              {props.list.map((data) => (
                <div className="flex items-center gap-3 mt-5" key={data.id}>
                  <BsFillPatchCheckFill className="w-6 h-6 text-logo" />
                  <p className="text-lg font-proxima-regular text-black">
                    {data.name}
                  </p>
                </div>
              ))}
            </div>
          )}
          <p className="text-lg font-proxima-regular text-grey mt-5">
            {props?.footerLine}
          </p>
        </div>
        <div className="col-span-1 order-1 xl:order-2 lg:order-2">
          <div className="w-full h-auto bg-gradient-to-br from-logo to-transparent shadow-md rounded-lg relative overflow-hidden p-2 xl:p-5 lg:p-5">
            <img
              src={props.image}
              className="w-full h-auto"
              alt={props.title}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-10 xl:gap-20 lg:gap-20 items-center">
        <div className="col-span-1">
          <div className="w-full h-auto bg-gradient-to-br from-logo to-transparent shadow-md rounded-lg relative overflow-hidden p-2 xl:p-5 lg:p-5">
            <img
              src={props.image}
              className="w-full h-auto"
              alt={props.title}
            />
          </div>
        </div>
        <div className="col-span-1">
          <h2 className="text-2xl xl:text-3xl lg:text-3xl font-helvetica-bold text-black tracking-tight">
            {props.title}
          </h2>
          <p className="text-lg font-proxima-regular text-grey mt-5">
            {props.description}
          </p>
          {props.flatList ? (
            <div className="mt-5">
              <p className="text-lg font-proxima-regular text-grey">
                {props.listHeader}
              </p>
              <div className="flex items-center flex-wrap gap-y-5 gap-8 mt-5">
                {props.list.map((data) => (
                  <div className="flex items-center gap-3" key={data.id}>
                    <BsFillPatchCheckFill className="w-6 h-6 text-logo" />
                    <p className="text-lg font-proxima-semibold text-black">
                      {data.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="mt-5">
              <p className="text-lg font-proxima-regular text-grey">
                {props.listHeader}
              </p>
              {props.list.map((data) => (
                <div className="flex items-center gap-3 mt-5" key={data.id}>
                  <BsFillPatchCheckFill className="w-6 h-6 text-logo" />
                  <p className="text-lg font-proxima-regular text-black">
                    {data.name}
                  </p>
                </div>
              ))}
            </div>
          )}
          <p className="text-lg font-proxima-regular text-grey mt-5">
            {props?.footerLine}
          </p>
        </div>
      </div>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* BANNER */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-10 xl:gap-20 lg:gap-20 items-center">
            <div className="col-span-1 w-full xl:w-[95%] lg:w-[95%]">
              <div className="w-fit bg-logo bg-opacity-10 rounded-full px-5 py-2 border border-logo border-opacity-40">
                <p className="text-base font-proxima-semibold text-logo">
                  Made in India, Made for India
                </p>
              </div>
              <div className="mt-5 xl:mt-10 lg:mt-10">
                <h1 className="text-3xl xl:text-4xl lg:text-4xl font-helvetica-bold tracking-tight text-black leading-snug">
                  Pioneering India's first comprehensive automated digital
                  trading journal.
                </h1>
              </div>
              <div className="mt-5">
                <p className="text-lg xl:text-xl lg:text-xl font-proxima-regular text-grey">
                  TradeTalez acts as your indispensable digital ally, enabling
                  effortless trade journaling and comprehensive analytics,
                  empowering smarter trading decisions through enhanced insights
                  and analysis capabilities.
                </p>
              </div>
              <div className="mt-5 xl:mt-10 lg:mt-10">
                <div className="w-full mt-5 xl:mt-0 lg:mt-0 block xl:flex lg:flex items-center gap-3">
                  <Link to="/pricing">
                    <Button className="w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-logo border border-logo rounded-lg text-white font-proxima-semibold text-base hover:bg-opacity-80 transition-all duration-300 ease-in-out normal-case">
                      Start Journaling
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="w-full h-[50vh] xl:h-[65vh] lg:h-[65vh] rounded-xl newsletter-gradient relative overflow-hidden shadow-xl">
                <div className="absolute top-10 left-10 w-[110vh] h-[70vh]">
                  <img
                    src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/features/feature-hero-banner.png"
                    className="w-full h-full object-contain"
                    alt="Pioneering India's first comprehensive automated digital trading journal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* BROKERS INTEGRATED */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-12 lg:py-12 bg-greyBg"
      >
        <div className="container">
          <div className="block xl:flex lg:flex items-center justify-center xl:justify-between lg:justify-between">
            <div className="w-full xl:w-[30%] lg:w-[30%]">
              <h5 className="text-xl xl:text-2xl lg:text-2xl font-proxima-bold text-black text-center xl:text-left lg:text-left">
                Brokers supported with TradeTalez
              </h5>
            </div>
            <div className="w-full xl:w-[65%] lg:w-[65%] mt-5 xl:mt-0 lg:mt-0">
              <div className="flex items-center gap-5 xl:gap-0 lg:gap-0 justify-center xl:justify-between lg:justify-between flex-wrap">
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/zerodha.webp"
                  className="h-[3vh] w-auto object-contain"
                  alt="Zerodha"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/upstox-logo.webp"
                  className="h-[6vh] w-auto object-contain"
                  alt="Upstox"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/dhan-logo.webp"
                  className="h-[4vh] w-auto object-contain"
                  alt="Dhan"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/fyers-logo.webp"
                  className="h-[4vh] w-auto object-contain"
                  alt="Fyers"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/angelone-logo.webp"
                  className="h-[4vh] w-auto object-contain"
                  alt="AngelOne"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/motilal-logo.webp"
                  className="h-[4vh] w-auto object-contain"
                  alt="AngelOne"
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FEATURES LIST */}
      {featureSegregation.map((data, index) => (
        <motion.div
          variants={animation}
          className={`py-5 xl:py-12 lg:py-12 ${
            (index + 1) % 2 === 0 ? "bg-logo bg-opacity-5" : "bg-white"
          }`}
          key={data.id}
        >
          <div className="container">
            <FeatureBox
              index={index + 1}
              image={data.image}
              title={data.title}
              description={data.description}
              list={data.list}
              listHeader={data.listHeader}
              flatList={data.flatList}
              footerLine={data.footerLine}
            />
          </div>
        </motion.div>
      ))}

      {/* TESTIMONIALS */}
      <Testimonials />
    </motion.section>
  );
};

export default FeaturesPage;
