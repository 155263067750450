/* PACKAGES */
import { motion } from "framer-motion";

/* ANIMATION */
import { animation, containerAnimation } from "data/animation";

const TermsofService = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* BANNER */}
      <motion.div variants={animation} className="py-10 bg-[#f6f5f0]">
        <div className="container mx-auto">
          <h1 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold text-black tracking-tight">
            Terms and Conditions
          </h1>
          <p className="mt-1 text-lg xl:text-xl lg:text-xl font-proxima-regular text-grey leading-snug">
            We actively solicit feedback on our terms of service; please send
            any comments or questions to{" "}
            <a
              href="mailto:contact@tradetalez.com"
              className="text-logo font-proxima-semibold"
            >
              contact@tradetalez.com
            </a>
          </p>
        </div>
      </motion.div>

      {/* CONTENT */}
      <motion.div variants={animation} className="py-10">
        <div className="container">
          <div className="p-0">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              1. User's Acknowledgment and Acceptance of Terms
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              TradeTalez ("TradeTalez", "Us", or "We") provides the TradeTalez
              site and various related services(collectively, the "site") to
              you, the user, subject to your compliance with all the terms,
              conditions, and notices contained or referenced herein(the "Terms
              of Service"), as well as any other written agreement between us
              and you. In addition, when using particular services or materials
              on this site, users shall be subject to any posted rules
              applicable to such services or materials that may contain terms
              and conditions in addition to those in these Terms of Service. All
              such guidelines or rules are hereby incorporated by reference into
              these Terms of Service.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              BY USING THIS SITE, YOU AGREE TO BE BOUND BY THESE TERMS OF
              SERVICE.IF YOU DO NOT WISH TO BE BOUND BY THE THESE TERMS OF
              SERVICE, PLEASE EXIT THE SITE NOW.YOUR REMEDY FOR DISSATISFACTION
              WITH THIS SITE, OR ANY PRODUCTS, SERVICES, CONTENT, OR OTHER
              INFORMATION AVAILABLE ON OR THROUGH THIS SITE, IS TO STOP USING
              THE SITE AND / OR THOSE PARTICULAR PRODUCTS OR SERVICES.YOUR
              AGREEMENT WITH US REGARDING COMPLIANCE WITH THESE TERMS OF SERVICE
              BECOMES EFFECTIVE IMMEDIATELY UPON COMMENCEMENT OF YOUR USE OF
              THIS SITE.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              These Terms of Service are effective as of October 29th, 2020. We
              expressly reserve the right to change these Terms of Service from
              time to time without notice to you. You acknowledge and agree that
              it is your responsibility to review this site and these Terms of
              Service from time to time and to familiarize yourself with any
              modifications. Your continued use of this site after such
              modifications will constitute acknowledgement of the modified
              Terms of Service and agreement to abide and be bound by the
              modified Terms of Service. As used in these Terms of Service,
              references to our "Affiliates" include our owners, subsidiaries,
              affiliated companies, officers, directors, suppliers, partners,
              sponsors, and advertisers, and includes(without limitation) all
              parties involved in creating, producing, and / or delivering this
              site and / or its contents.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              2. Description of Services
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              We make various services available on this site including, but not
              limited to, trade journaling, trade analysis, sharing or
              publishing of trades, and other like services. You are responsible
              for providing, at your own expense, all equipment necessary to use
              the services, including a computer and Internet access (including
              payment of all fees associated with such access). We reserve the
              sole right to either modify or discontinue the site, including any
              of the site's features, at any time with or without notice to you.
              We will not be liable to you or any third party should we exercise
              such right. Any new features that augment or enhance the
              then-current services on this site shall also be subject to these
              Terms of Service.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              3. Registration Data and Privacy
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              In order to access some of the services on this site, you will be
              required to use an account and password that can be obtained by
              completing our online registration form, which requests certain
              information and data , and maintaining and updating your
              Registration Data as required. By registering, you agree that all
              information provided in the Registration Data is true and accurate
              and that you will maintain and update this information as required
              in order to keep it current, complete, and accurate.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You also grant us the right to disclose to third parties certain
              Registration Data about you, but only as specifically listed in
              our Privacy Policy.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              4. Conduct on Site
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Your use of the site is subject to all applicable laws and
              regulations, and you are solely responsible for the substance of
              your communications through the site. By posting information in or
              otherwise using any communications service, chat room, message
              board, newsgroup, software library, or other interactive service
              that may be available to you on or through this site, you agree
              that you will not upload, share, post, or otherwise distribute or
              facilitate distribution of any content -- including text,
              communications, software, images, sounds, data, or other
              information -- that:
            </p>
            <ul className="ml-8 mt-3 list-decimal">
              <li className="text-base font-proxima-regular text-grey mt-2">
                is unlawful, threatening, abusive, harassing, defamatory,
                libelous, deceptive, fraudulent, invasive of another's privacy,
                tortious, contains explicit or graphic descriptions or accounts
                of sexual acts (including but not limited to sexual language of
                a violent or threatening nature directed at another individual
                or group of individuals), or otherwise violates our rules or
                policies;
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                victimizes, harasses, degrades, or intimidates an individual or
                group of individuals on the basis of religion, gender, sexual
                orientation, race, ethnicity, age, or disability;
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                infringes on any patent, trademark, trade secret, copyright,
                right of publicity, or other proprietary right of any party;
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                constitutes unauthorized or unsolicited advertising, junk or
                bulk email (also known as "spamming"), chain letters, any other
                form of unauthorized solicitation, or any form of lottery or
                gambling;
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                contains software viruses or any other computer code, files, or
                programs that are designed or intended to disrupt, damage, or
                limit the functioning of any software, hardware, or
                telecommunications equipment or to damage or obtain unauthorized
                access to any data or other information of any third party; or
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                impersonates any person or entity, including any of our
                employees or representatives.
              </li>
            </ul>
            <p className="text-base font-proxima-regular text-grey mt-3">
              We neither endorse nor assume any liability for the contents of
              any material uploaded or submitted by third party users of the
              site. We generally do not pre-screen, monitor, or edit the content
              posted by users of communications services, chat rooms, message
              boards, newsgroups, software libraries, or other interactive
              services that may be available on or through this site. However,
              we and our agents have the right at their sole discretion to
              remove any content that, in our judgment, does not comply with
              these Terms of Service and any other rules of user conduct for our
              site, or is otherwise harmful, objectionable, or inaccurate.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              We are not responsible for any failure or delay in removing such
              content. You hereby consent to such removal and waive any claim
              against us arising out of such removal of content. See "Use of
              Your Materials" below for a description of the procedures to be
              followed in the event that any party believes that content posted
              on this site infringes on any patent, trademark, trade secret,
              copyright, right of publicity, or other proprietary right of any
              party.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              In addition, you may not use your account to breach security of
              another account or attempt to gain unauthorized access to another
              network or server. Not all areas of the site may be available to
              you or other authorized users of the site. You shall not interfere
              with anyone else’s use and enjoyment of the site or other similar
              services. Users who violate systems or network security may incur
              criminal or civil liability.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You agree that we may at any time, and at our sole discretion,
              terminate your membership, account, or other affiliation with our
              site without prior notice to you for violating any of the above
              provisions. In addition, you acknowledge that we will cooperate
              fully with investigations of violations of systems or network
              security at other sites, including cooperating with law
              enforcement authorities in investigating suspected criminal
              violations.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              5. Subscriptions
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Subscriptions are sold on this site, and are generally paid in
              advance to receive the service.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              6. Third Party Sites and Information
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              This site may link you to other sites on the Internet or otherwise
              include references to information, documents, software, materials
              and/or services provided by other parties. These sites may contain
              information or material that some people may find inappropriate or
              offensive. These other sites and parties are not under our
              control, and you acknowledge that we are not responsible for the
              accuracy, copyright compliance, legality, decency, or any other
              aspect of the content of such sites, nor are we responsible for
              errors or omissions in any references to other parties or their
              products and services. The inclusion of such a link or reference
              is provided merely as a convenience and does not imply endorsement
              of, or association with, the site or party by us, or any warranty
              of any kind, either express or implied.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              7. Intellectual Property Information
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Copyright © 2024 TradeTalez, All Rights Reserved.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              For purposes of these Terms of Service, "content" is defined as
              any information, data, communications, software, photos, video,
              graphics, music, sounds, and other material and services that can
              be viewed by users on our site.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              By accepting these Terms of Service, you acknowledge and agree
              that all content presented to you on this site is protected by
              copyrights, trademarks, service marks, patents or other
              proprietary rights and laws, and is the sole property of
              TradeTalez. You are only permitted to use the content as expressly
              authorized by us or the specific content provider. Except for a
              single copy made for personal use only, you may not copy,
              reproduce, modify, republish, upload, post, transmit, or
              distribute any documents or information from this site in any form
              or by any means without prior written permission from us or the
              specific content provider, and you are solely responsible for
              obtaining permission before reusing any copyrighted material that
              is available on this site. Any unauthorized use of the materials
              appearing on this site may violate copyright, trademark and other
              applicable laws and could result in criminal or civil penalties.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Neither we or our Affiliates warrant or represent that your use of
              materials displayed on, or obtained through, this site will not
              infringe the rights of third parties. See "User's Materials" below
              for a description of the procedures to be followed in the event
              that any party believes that content posted on this site infringes
              on any patent, trademark, trade secret, copyright, right of
              publicity, or other proprietary right of any party.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              All custom graphics, icons, logos and service names are registered
              trademarks, trademarks or service marks of TradeTalez . All other
              trademarks or service marks are property of their respective
              owners. Nothing in these Terms of Service grants you any right to
              use any trademark, service mark, logo, and/or the name of
              TradeTalez or its Affiliates.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              8. Accuracy of Information
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              All of the information on this website is for entertainment and
              educational purposes only.While the information is believed to be
              accurate, none of the information on this site should be
              considered solely reliable for use in making actual investment
              decisions.TradeTalez is a distributor(and not a publisher) of
              content supplied by third parties.None of the information on this
              website is guaranteed to be accurate, complete, useful or
              timely.TradeTalez and its third- party providers do not warrant or
              guarantee such accuracy, completeness, usefulness or
              timeliness.TradeTalez neither endorses nor is responsible for the
              accuracy or reliability of any opinion, advice or statement made
              on TradeTalez.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              9. Investment Decisions
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You assume all risk associated with investment decisions made on
              the basis of information contained on this web site. It is our
              policy to never advocate the purchase or sale of any individual
              investment vehicle. You should also understand that TradeTalez,
              its third-party providers and their respective officers, directors
              and employees may be active investors in the market and may or may
              not have open positions in any investment vehicle mentioned on
              this website. Prior to the execution of a trade, you are advised
              to consult with your broker or other financial representative to
              verify pricing and other information. TradeTalez, its third-party
              providers, and/or content partners shall have no liability for
              investment decisions based upon information found on this web
              site.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              10. Shared Trades
            </h5>
            <p className="text-base font-proxima-semibold text-black mt-2">
              Posting and Commenting
            </p>
            <ul className="ml-8 mt-3 list-decimal">
              <li className="text-base font-proxima-regular text-grey mt-2">
                By posting and sharing your Content using the Services, you are
                granting an unrestricted, irrevocable, non-exclusive,
                royalty-free, perpetual, worldwide, and fully transferable,
                assignable, and sublicensable right and license to use, copy,
                reproduce, modify, adapt, publish, translate, create collective
                or derivative works from, distribute, perform and display your
                Content in whole or in part and to incorporate it in other works
                in any form, media, or technology now known or later developed,
                as necessary to provide the Services.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                By posting content to the service, you warrant and represent
                that you either own or otherwise control all of the rights to
                that content, including, without limitation, all the rights
                necessary for you to provide, post, upload, input or submit the
                content, or that your use of the content is a protected fair
                use. You agree that you will not knowingly provide material and
                misleading false information. You represent and warrant also
                that the content you supply does not violate these Terms. It is
                your sole responsibility to ensure that your postings do not
                disclose information covered by a nondisclosure agreement, and
                information that you are not authorized to disclose. We caution
                you not to disclose personal information about yourself or your
                children, such as social security numbers, credit card numbers,
                etc.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                You agree to indemnify and hold Us and Our affiliated companies,
                and their directors, officers and employees, harmless for any
                and all claims or demands, including reasonable attorney fees,
                that arise from or otherwise relate to your use of the service,
                any content you supply to the service, or your violation of
                these Terms or the rights of another.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                We shall have the right in Our sole discretion to terminate your
                access to and use of the Services and/or remove any of your
                Content should We consider your statements or conduct to be
                inaccurate, illegal, obscene, defamatory, threatening,
                infringing of intellectual property rights, invasive of privacy,
                injurious, objectionable, or otherwise in violation of these
                Terms or applicable law.
              </li>
            </ul>

            <p className="text-base font-proxima-semibold text-black mt-3">
              Accessing
            </p>
            <ul className="ml-8 mt-3 list-decimal">
              <li className="text-base font-proxima-regular text-grey mt-2">
                You agree that We will not be liable, under any circumstances
                and in any way, for any errors or omissions, loss or damage of
                any kind incurred as a result of use of any content posted on
                this site. You agree that you must evaluate and bear all risks
                associated with the use of any content, including any reliance
                on the accuracy, completeness, or usefulness of such content.
                You agree not to collect information about others, including
                e-mail addresses, or to use information obtained from the
                Services to send other users unsolicited e-mail of any kind.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                The service is provided for informational purposes only; we
                shall not be responsible or liable for the accuracy or
                availability of any information appearing or available on the
                service.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                Shared trades may provide links to other websites on the
                Internet. We are not responsible or liable for such content and
                we make no express or implied warranty about the accuracy,
                copyright compliance, legality, merchantability, or any other
                aspect of the content of such postings. We are not responsible
                or liable for any advertising, products, or other materials on
                or available from such websites or resources. The inclusion of
                links does not imply endorsement of the Websites by Us or any
                association with their operators.
              </li>
            </ul>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              11. Disclaimer of Warranties
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              ALL MATERIALS AND SERVICES ON THIS SITE ARE PROVIDED ON AN "AS IS"
              AND "AS AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND, EITHER
              EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
              WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE,
              OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE
              FOREGOING, WE MAKE NO WARRANTY THAT (A) THE SERVICES AND MATERIALS
              WILL MEET YOUR REQUIREMENTS, (B) THE SERVICES AND MATERIALS WILL
              BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS
              THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS
              WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY OF ANY
              PRODUCTS, SERVICES, OR INFORMATION PURCHASED OR OBTAINED BY YOU
              FROM THE SITE FROM US OR OUR AFFILIATES WILL MEET YOUR
              EXPECTATIONS OR BE FREE FROM MISTAKES, ERRORS OR DEFECTS.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              THIS SITE COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES
              OR TYPOGRAPHICAL ERRORS.WE MAY MAKE CHANGES TO THE MATERIALS AND
              SERVICES AT THIS SITE, INCLUDING THE PRICES AND DESCRIPTIONS OF
              ANY PRODUCTS LISTED HEREIN, AT ANY TIME WITHOUT NOTICE.THE
              MATERIALS OR SERVICES AT THIS SITE MAY BE OUT OF DATE, AND WE MAKE
              NO COMMITMENT TO UPDATE SUCH MATERIALS OR SERVICES.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER ACQUISITION OF
              ANY MATERIALS THROUGH THIS SITE IS DONE AT YOUR OWN DISCRETION AND
              RISK AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE
              FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT
              RESULTS FROM SUCH ACTIVITIES.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Through your use of the site, you may have the opportunities to
              engage in commercial transactions with other users and vendors.
              You acknowledge that all transactions relating to any merchandise
              or services offered by any party, including, but not limited to
              the purchase terms, payment terms, warranties, guarantees,
              maintenance and delivery terms relating to such transactions, are
              agreed to solely between the seller or purchaser of such
              merchandize and services and you. WE MAKE NO WARRANTY REGARDING
              ANY TRANSACTIONS EXECUTED THROUGH, OR IN CONNECTION WITH THIS
              SITE, AND YOU UNDERSTAND AND AGREE THAT SUCH TRANSACTIONS ARE
              CONDUCTED ENTIRELY AT YOUR OWN RISK. ANY WARRANTY THAT IS PROVIDED
              IN CONNECTION WITH ANY PRODUCTS, SERVICES, MATERIALS, OR
              INFORMATION AVAILABLE ON OR THROUGH THIS SITE FROM A THIRD PARTY
              IS PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY US OR ANY OTHER
              OF OUR AFFILIATES.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Content available through this site often represents the opinions
              and judgments of an information provider, site user, or other
              person or entity not connected with us. We do not endorse, nor are
              we responsible for the accuracy or reliability of, any opinion,
              advice, or statement made by anyone.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You understand and agree that temporary interruptions of the
              services available through this site may occur as normal events.
              You further understand and agree that we have no control over
              third party networks you may access in the course of the use of
              this site, and therefore, delays and disruption of other network
              transmissions are completely beyond our control.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You understand and agree that the services available on this site
              are provided "AS IS" and that we assume no responsibility for the
              timeliness, deletion, mis-delivery or failure to store any user
              communications or personalization settings.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              12. Limitation of Liability
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              IN NO EVENT SHALL WE OR OUR AFFILIATES BE LIABLE TO YOU OR ANY
              THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR
              CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER,
              INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE,
              DATA OR PROFITS, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY,
              ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS SITE OR OF
              ANY WEB SITE REFERENCED OR LINKED TO FROM THIS SITE.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR THIRD PARTY GOODS
              AND SERVICES OFFERED THROUGH THIS SITE OR FOR ASSISTANCE IN
              CONDUCTING COMMERCIAL TRANSACTIONS THROUGH THIS SITE, INCLUDING
              WITHOUT LIMITATION THE PROCESSING OF ORDERS.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              13. Indemnification
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Upon a request by us, you agree to defend, indemnify, and hold us
              and our Affiliates harmless from all liabilities, claims, and
              expenses, including attorney’s fees, that arise from your use or
              misuse of this site. We reserve the right, at our own expense, to
              assume the exclusive defense and control of any matter otherwise
              subject to indemnification by you, in which event you will
              cooperate with us in asserting any available defenses.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              14. Security and Password
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You are solely responsible for maintaining the confidentiality of
              your password and account and for any and all statements made and
              acts or omissions that occur through the use of your password and
              account. Therefore, you must take steps to ensure that others do
              not gain access to your password and account. Our personnel will
              never ask you for your password. You may not transfer or share
              your account with anyone, and we reserve the right to immediately
              terminate your account if you do transfer or share your account.
              You agree to immediately notify us of any unauthorized use of your
              password or account or any other breach of security. We are not
              responsible or liable for any loss or damage arising from your
              failure to comply with this provision.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              15. Termination of Use
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You agree that we may, in our sole discretion, terminate or
              suspend your access to all or part of the site with or without
              notice and for any reason, including, without limitation, breach
              of these Terms of Service. Any suspected fraudulent, abusive or
              illegal activity may be grounds for terminating your relationship
              and may be referred to appropriate law enforcement authorities.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Upon termination or suspension, regardless of the reasons
              therefore, your right to use the services available on this site
              immediately ceases, and you acknowledge and agree that we may
              immediately deactivate or delete your account and all related
              information and files in your account and/or bar any further
              access to such files or this site. We shall not be liable to you
              or any third party for any claims or damages arising out of any
              termination or suspension or any other actions taken by us in
              connection with such termination or suspension.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              16. Notices
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              All notices to a party shall be in writing and shall be made via
              email. Notices to us must be sent to the attention of Customer
              Service at contact@tradetalez.com In addition, we may broadcast
              notices or messages through the site to inform you of changes to
              the site or other matters of importance, and such broadcasts shall
              constitute notice to you at the time of sending.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              17. Entire Agreement
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              These terms and conditions constitute the entire agreement and
              understanding between us concerning the subject matter of this
              agreement and supersedes all prior agreements and understandings
              of the parties with respect to that subject matter. These Terms of
              Service may not be altered, supplemented, or amended by the use of
              any other document(s). Any attempt to alter, supplement or amend
              this document or to enter an order for products or services which
              are subject to additional or altered terms and conditions shall be
              null and void, unless otherwise agreed to in a written agreement
              signed by you and us. To the extent that anything in or associated
              with this site is in conflict or inconsistent with these Terms of
              Service, these Terms of Service shall take precedence.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              18. Miscellaneous
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              In any action to enforce these Terms of Service, the prevailing
              party will be entitled to costs and attorneys’ fees. Any cause of
              action brought by you against us or our Affiliates must be
              instituted with one year after the cause of action arises or be
              deemed forever waived and barred.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You may not assign your rights and obligations under these Terms
              of Service to any party, and any purported attempt to do so will
              be null and void. We may freely assign our rights and obligations
              under these Terms of Service.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You agree not to sell, resell, reproduce, duplicate, copy or use
              for any commercial purposes any portion of this site, or use of or
              access to this site.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              In addition to any excuse provided by applicable law, we shall be
              excused from liability for non-delivery or delay in delivery of
              products and services available through our site arising from any
              event beyond our reasonable control, whether or not foreseeable by
              either party, including but not limited to, labor disturbance,
              war, fire, accident, adverse weather, inability to secure
              transportation, governmental act or regulation, and other causes
              or events beyond our reasonable control, whether or not similar to
              those which are enumerated above.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              19. Contact Information
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Except as explicitly noted on this site, the services available
              through this site are offered by TradeTalez. If you notice that
              any user is violating these Terms of Service, please contact us at{" "}
              <a
                href="mailto:contact@tradetalez.com"
                className="text-logo font-proxima-semibold"
              >
                contact@tradetalez.com
              </a>
            </p>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default TermsofService;
