/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* PACKAGES */
import { AnimatePresence } from "framer-motion";

/* LAYOUT */
import Layout from "layout";

/* STYLES */
import "styles/global.css";

/* HOMEPAGE */
import Homepage from "pages/index";

/* PRICING */
import Pricing from "pages/pricing";

/* WAITING LIST */
import WaitList from "pages/waitlist";

/* CONTACT US */
import ContactUs from "pages/contact-us";

/* BROKER SUPPORT */
import BrokerSupport from "pages/broker-support";

/* FEATURES */
import Features from "pages/features";
import ResourceCenter from "pages/resource-center";

/* POLICIES */
import TermsofService from "pages/policies/terms-conditions";
import PrivacyPolicy from "pages/policies/privacy-policy";
import RefundPolicy from "pages/policies/refund-policy";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <AnimatePresence>
        <Router>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/waitlist" element={<WaitList />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/broker-support" element={<BrokerSupport />} />
              <Route path="/resource-center" element={<ResourceCenter />} />
              <Route path="/our-features" element={<Features />} />

              {/* POLICIES */}
              <Route path="/terms-conditions" element={<TermsofService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
            </Routes>
          </Layout>
        </Router>
      </AnimatePresence>
    </div>
  );
};

export default AppRoutes;
