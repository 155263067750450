import React, { useEffect, useState } from "react";
import { Link as NavLink } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";

/* ANIMATION DATA */
import { animation, containerAnimation } from "data/animation";

/* DATA */
import testimonials from "data/testimonials";
import { FaVideo } from "react-icons/fa6";

const Homepage = () => {
  const [scrollPosition, setScrollPosition] = useState(false);

  /* TESTIMONIAL BOX */
  const TestimonialBox = (props) => {
    return (
      <div className="w-full bg-white border border-greyBorder rounded-lg shadow p-5 first:mt-0 mt-5">
        {/* NAME & DESIGNATION */}
        <div className="flex items-center gap-3">
          <img
            src={props.profile}
            className="w-10 h-10 rounded-full object-cover"
            alt={props.name}
          />
          <div className="p-0">
            <p className="text-base font-proxima-semibold text-black">
              {props.name}
            </p>
            <p className="text-sm font-proxima-regular text-grey">
              {props.designation}
            </p>
          </div>
        </div>

        {/* CONTENT */}
        <div className="mt-3">
          <p className="text-sm font-proxima-regular text-black">
            {props.testimonialText}
          </p>
          <p className="mt-3 text-sm font-proxima-regular text-grey">
            {props.date}
          </p>
        </div>
      </div>
    );
  };

  /* DIFFICULTIES CARD */
  const DifficultiesCard = (props) => {
    return (
      <div className="w-full rounded-xl border border-greyBorder shadow-md bg-white p-5 xl:p-10 lg:p-10">
        <div className="w-16 h-16 bg-logo bg-opacity-10 border border-logo border-opacity-30 rounded-full flex items-center justify-center">
          <p className="text-2xl font-proxima-bold text-black">
            0{props.number}
          </p>
        </div>
        <div className="mt-5">
          <h5 className="text-xl xl:text-2xl lg:text-2xl font-proxima-bold text-black">
            {props.title}
          </h5>
          <p className="mt-3 font-proxima-regular text-grey text-base xl:text-lg lg:text-lg leading-relaxed">
            {props.description}
          </p>
        </div>
      </div>
    );
  };

  /* HANDLE SCROLL */
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  /* USE EFFECT */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* BANNER */}
      <motion.div
        variants={animation}
        className="relative py-10 pb-0 xl:py-20 lg:py-20"
      >
        {/* CONTENT AND IMAGE */}
        <div className="container h-full flex flex-col items-center relative z-50">
          <div className="w-full xl:w-3/4 lg:w-3/4 m-auto">
            <h1 className="text-3xl xl:text-[5.5vh] lg:text-[5.5vh] font-helvetica-bold tracking-tight text-white text-center leading-[1.6] relative">
              India's Most Affordable <br />
              Automated Trading Journal!
              <div className="absolute top-[4.5vh] xl:top-[7.5vh] lg:top-[7.5vh] right-[3.5vh] xl:right-[30vh] lg:right-[30vh]">
                <img
                  src={require("../assets/images/underline.png")}
                  className="w-[25vh] xl:w-[40vh] lg:w-[40vh] h-[1.5vh] xl:h-[3vh] lg:h-[3vh]"
                  alt="Most affordable"
                />
              </div>
            </h1>
            <h2 className="mt-5 xl:mt-8 lg:mt-8 text-white text-opacity-70 font-proxima-regular text-center text-lg xl:text-2xl lg:text-xl">
              TradeTalez serves as your essential digital partner, facilitating
              seamless trade journaling and robust analytics, empowering you to
              trade with greater intelligence.
            </h2>
            <div className="mt-10 flex items-center justify-center gap-3">
              <NavLink to="/pricing">
                <Button className="px-6 shadow-none hover:shadow-none bg-logo hover:bg-opacity-80 transition-all duration-300 ease-in-out rounded-lg text-base font-proxima-semibold text-white flex items-center gap-2 normal-case justify-center">
                  Get started
                  <MdOutlineArrowOutward className="w-5 h-5 text-white" />
                </Button>
              </NavLink>
              <NavLink to="/resource-center">
                <Button className="px-6 shadow-none hover:shadow-none bg-transparent hover:bg-white hover:text-black transition-all duration-300 ease-in-out border border-white rounded-lg text-base font-proxima-semibold text-white flex items-center gap-3 normal-case justify-center">
                  <FaVideo className="w-5 h-5 " />
                  Watch demo
                </Button>
              </NavLink>
            </div>
          </div>

          {/* BANNER IMAGE */}
          <div
            className={`${
              scrollPosition > 50 ? "banner-image " : "banner-image rotate-img"
            }`}
          >
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/trade-talez-mockup.webp"
              className="w-full h-auto object-cover rounded-2xl"
              alt="Trade Talez Tool"
            />
          </div>
        </div>

        {/* BACKGROUND GRADIENT */}
        <div className="newsletter-gradient h-[60vh] xl:h-mainBanner lg:h-mainBanner absolute top-0 left-0 w-full">
          {/* TOP RIGHT */}
          <div className="absolute top-0 right-0 overflow-hidden">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/banner-circle.webp"
              className="w-[15vh] xl:w-[27vh] lg:w-[27vh] h-auto banner-shape hidden xl:block lg:block"
              alt="Simplify, Analyze, and Optimize Your Trading Experience"
            />
          </div>

          {/* BOTTOM LEFT */}
          <div className="absolute bottom-0 left-0 overflow-hidden">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/banner-circle.webp"
              className="w-[15vh] xl:w-[27vh] lg:w-[27vh] h-auto banner-shape-bottom hidden xl:block lg:block"
              alt="Simplify, Analyze, and Optimize Your Trading Experience"
            />
          </div>
        </div>
      </motion.div>

      {/* SUPPORTED BROKERS */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-12 lg:py-12 bg-greyBg"
      >
        <div className="container">
          <div className="block xl:flex lg:flex items-center justify-between">
            <div className="w-full xl:w-[65%] lg:w-[65%]">
              <h2 className="text-2xl font-proxima-bold text-black text-center">
                Supported Brokers
              </h2>
              <div className="flex items-center justify-center xl:justify-between lg:justify-between mt-5 flex-wrap gap-5 xl:gap-0 lg:gap-0">
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/zerodha.webp"
                  className="h-[3vh] w-auto object-contain"
                  alt="Zerodha"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/upstox-logo.webp"
                  className="h-[5vh] w-auto object-contain"
                  alt="Upstox"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/dhan-logo.webp"
                  className="h-[5vh] w-auto object-contain"
                  alt="Dhan"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/fyers-logo.webp"
                  className="h-[4vh] w-auto object-contain"
                  alt="Fyers"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/angelone-logo.webp"
                  className="h-[3vh] w-auto object-contain"
                  alt="AngelOne"
                />
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/motilal-logo.webp"
                  className="h-[4vh] w-auto object-contain"
                  alt="Motilal Oswal"
                />
              </div>
            </div>
            <div className="w-full xl:w-[25%] lg:w-[25%] mt-10 xl:mt-0 lg:mt-0">
              <h2 className="text-2xl font-proxima-bold text-black text-center">
                To Be Added
              </h2>
              <div className="flex items-center gap-5 xl:gap-0 lg:gap-0 justify-around mt-5 flex-wrap">
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/groww-logo.webp"
                  className="h-[3vh] w-auto object-contain"
                  alt="Groww"
                />
                {/* 
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/motilal-logo.webp"
                  className="h-[5vh] w-auto object-contain"
                  alt="Motilal Oswal"
                /> */}
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/icici-direct-logo.webp"
                  className="h-[4vh] w-auto object-contain"
                  alt="ICICI Direct"
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT US */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14"
        id="about-us"
      >
        <div className="container">
          <div className="w-full rounded-xl">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-10 lg:gap-10 items-center">
              {/* IMAGE */}
              <div className="col-span-1 relative shadow-md rounded-lg">
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/company-logo.png"
                  className="w-[90%] mx-auto h-auto rounded-lg"
                  alt="Tactical Trading Technologies LLP"
                />
              </div>

              {/* CONTENT */}
              <div className="col-span-1 md:px-4">
                <h5 className="text-xl xl:text-2xl lg:text-2xl font-proxima-semibold text-logo">
                  About Us
                </h5>
                <h2 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold tracking-tight text-black">
                  Our Trading Tale
                </h2>
                <div className="mt-5">
                  <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-grey">
                    At{" "}
                    <span className="text-black font-proxima-bold">
                      Tactical Trading Technologies LLP
                    </span>
                    , our mission is to revolutionize the trading landscape for
                    Indian individual and traders. Our flagship product,
                    TradeTalez, goes beyond being a simple digital trading
                    journal. It's a sophisticated tool meticulously crafted to
                    simplify trade tracking and analysis, empowering traders to
                    make informed decisions effortlessly.
                  </p>
                  <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-grey mt-5">
                    With TradeTalez, we offer more than just a product; we
                    provide a tailored solution to meet the unique needs of
                    Indian traders. By seamlessly integrating various broker
                    APIs, we enable direct data synchronization from broker
                    accounts to our platform, eliminating manual data entry and
                    ensuring accurate trading records.
                  </p>
                  <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-grey mt-5">
                    Our commitment extends beyond integration; we prioritize
                    compliance with SEBI and broker regulations to ensure
                    traders can trade confidently while staying compliant.
                  </p>
                  <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-grey mt-5">
                    Join us in revolutionizing the trading journey for Indian
                    traders with TradeTalez.
                  </p>
                  <div className="flex items-center gap-3 mt-5 ">
                    <NavLink to="/pricing">
                      <Button className="shadow-none hover:shadow-none bg-logo rounded-lg text-base font-proxima-semibold text-white normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                        Start Journaling
                      </Button>
                    </NavLink>
                    <NavLink to="/resource-center">
                      <Button className="shadow-none hover:shadow-none bg-white hover:bg-logo border border-logo text-base font-proxima-semibold text-logo normal-case hover:bg-opacity-10 transition-all duration-300 ease-in-out">
                        Watch demo
                      </Button>
                    </NavLink>
                    {/* <a
                      href="https://www.instagram.com/tradetalez?igsh=MWc3bW1hdTRpZDdxZA=="
                      target="_blank"
                      rel="noreferrer"
                    ></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* TACKLING TRADING DIFFICULTIES */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 bg-[#f6f5f0]"
      >
        <div className="container px-5">
          <div className="p-0">
            <h2 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold tracking-tight text-black">
              Trading Difficulties
            </h2>
            <h5 className="mt-1 font-proxima-regular text-black text-opacity-80 text-lg xl:text-xl lg:text-xl">
              We understand the challenges faced by traders in the Indian
              market, including,
            </h5>
          </div>
          <div className="mt-5 xl:mt-10 lg:mt-10">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
              <div className="col-span-1">
                <DifficultiesCard
                  number="1"
                  title="Lack of Discipline"
                  description="Traders often face challenges with discipline, resulting in inconsistent outcomes. TradeTalez offers trade journaling, performance analysis, and personalized insights to promote accountability, consistency, and adherence to trading plans."
                />
              </div>
              <div className="col-span-1">
                <DifficultiesCard
                  number="2"
                  title="Insufficient Risk Management"
                  description="TradeTalez provides portfolio analysis for traders to resolve bad risk management habits by tracking their data in our app. Analyzing portfolios, evaluating risks, and simulating scenarios empower decisions and optimize performance."
                />
              </div>
              <div className="col-span-1">
                <DifficultiesCard
                  number="3"
                  title="Poor Emotional Control Leading"
                  description="Many traders struggle with emotional resilience and discipline in trading. TradeTalez offers tools like emotion journaling, behavior analysis, and trading psychology resources to foster emotional awareness and control, aiding in confident market navigation."
                />
              </div>
              <div className="col-span-1">
                <DifficultiesCard
                  number="4"
                  title="Significant financial losses"
                  description="TradeTalez enables traders to learn, identify patterns, and refine strategies to minimize losses and boost profitability. Through advanced analytics, traders gain insights, improve performance, and make data-driven decisions for enhanced success."
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* TESTIMONIALS */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 md:px-4 lg:px-0"
      >
        <div className="container">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2">
            <div className="col-span-1">
              <div className="w-full xl:w-4/5 lg:w-4/5 mt-5">
                <h5 className="text-xl font-proxima-semibold text-logo">
                  Testimonials
                </h5>
                <h2 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold text-black tracking-tight">
                  Hear what our clients say
                </h2>
                <p className="mt-2 text-lg xl:text-xl lg:text-xl font-proxima-regular text-grey leading-snug">
                  We don't just say it, we put it in action and take pride in
                  delivering results. Our mission is to empower traders with the
                  best journaling tool they need to thrive to manage their
                  trades and analyze their trading performance.
                </p>
                <NavLink to="/pricing">
                  <Button className="mt-8 shadow-none hover:shadow-none bg-logo rounded-lg text-base font-proxima-semibold text-white px-5 py-3 flex items-center gap-2 normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                    Start Journaling Today
                    <MdOutlineArrowOutward className="w-5 h-5 text-white" />
                  </Button>
                </NavLink>
              </div>
            </div>
            <div className="col-span-1">
              <div className="w-full overflow-hidden relative h-[80vh] xl:h-testimonialBox lg:h-testimonialBox">
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 py-5">
                  <div className="col-span-1">
                    {testimonials.slice(0, 3).map((data) => (
                      <React.Fragment key={data.id}>
                        <TestimonialBox
                          name={data.name}
                          designation={data.designation}
                          testimonialText={data.testimonialText}
                          value={data.value}
                          date={data.date}
                          profile={data.profile}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="col-span-1">
                    {testimonials.slice(3, 5).map((data) => (
                      <React.Fragment key={data.id}>
                        <TestimonialBox
                          name={data.name}
                          designation={data.designation}
                          testimonialText={data.testimonialText}
                          value={data.value}
                          date={data.date}
                          profile={data.profile}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </div>

                {/* GRADIENT */}
                <div className="absolute left-0 -bottom-1 w-full h-9 testimonial-gradient" />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Homepage;
