import React, { useState } from "react";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";

/* ANIMATION DATA */
import { animation, containerAnimation } from "data/animation";

/* SAMPLE DATA */
import testimonials from "data/testimonials";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* APIS */
import { contactUsAPI } from "service/api";

/* VALIDATION SCHEMA */
const formValidation = yup.object().shape({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  email: yup.string().email().required("Email Address is required."),
  phone: yup.string().required("Phone Number is required."),
  message: yup.string().required("Message is required."),
});

const ContactUs = () => {
  const [formLoading, setFormLoading] = useState(false);

  /* TESTIMONIAL BOX */
  const TestimonialBox = (props) => {
    return (
      <div className="w-full bg-white border border-greyBorder rounded-lg shadow p-5 first:mt-0 mt-5">
        {/* NAME & DESIGNATION */}
        <div className="flex items-center gap-3">
          <img
            src={props.profile}
            className="w-10 h-10 rounded-full object-cover"
            alt={props.name}
          />
          <div className="p-0">
            <p className="text-base font-proxima-semibold text-black">
              {props.name}
            </p>
            <p className="text-sm font-proxima-regular text-grey">
              {props.designation}
            </p>
          </div>
        </div>

        {/* CONTENT */}
        <div className="mt-3">
          <p className="text-sm font-proxima-regular text-black">
            {props.testimonialText}
          </p>
          <p className="mt-3 text-sm font-proxima-regular text-grey">
            {props.date}
          </p>
        </div>
      </div>
    );
  };

  /* CONTACT US FORM HANDLING */
  const formikHandling = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      const obj = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        message: values.message,
      };
      setFormLoading(true);
      contactUsAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Thank you for reaching out to us. We will get back to you in 1-2 business days."
            );
          } else {
            toast.error(res?.message);
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formikHandling.handleReset();
          setFormLoading(false);
        });
    },
  });

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTACT US FORM */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 md:px-4 lg:px-0"
      >
        <div className="container mx-auto">
          <div className="newsletter-gradient rounded-2xl shadow-xl p-5 xl:p-10 lg:p-10">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-center">
              <div className="col-span-1">
                <h2 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold tracking-tight gradient-text">
                  We'd love to hear from you!
                </h2>
                <h5 className="text-lg xl:text-xl lg:text-xl font-proxima-regular text-white text-opacity-70 mt-5">
                  Pick up the phone or shoot us an email - whatever floats your
                  boat. We'll be here, ready to provide the kind of support that
                  leaves you feeling heard, valued, and empowered to journal
                  your trades.
                </h5>
                <div className="mt-5 xl:mt-10 lg:mt-10">
                  <div className="grid grid-cols-1 gap-5">
                    <div className="col-span-1">
                      <h5 className="text-white font-proxima-bold text-2xl tracking-tight">
                        Chat with us
                      </h5>
                      <p className="mt-1 text-lg font-proxima-regular text-white text-opacity-70">
                        Our friendly staff is here to help you for your wellness
                        excellence.
                      </p>
                      <a
                        href="mailto:contact@tradetalez.com"
                        className="block text-xl font-proxima-bold text-logo mt-2 hover:underline transition-all duration-300 ease-in-out"
                      >
                        contact@tradetalez.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <form
                  className="bg-white p-5 xl:p-10 lg:p-10 rounded-xl shadow-xl"
                  onSubmit={formikHandling.handleSubmit}
                >
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          First Name <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={formikHandling.values.firstName}
                            onChange={formikHandling.handleChange}
                            className="outline-none w-full block bg-white border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold text-black placeholder:font-proxima-regular"
                          />
                          {formikHandling.errors.firstName &&
                            formikHandling.touched.firstName && (
                              <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                                {formikHandling.errors.firstName}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          Last Name <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={formikHandling.values.lastName}
                            onChange={formikHandling.handleChange}
                            className="outline-none w-full block bg-white border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold text-black placeholder:font-proxima-regular"
                          />
                          {formikHandling.errors.lastName &&
                            formikHandling.touched.lastName && (
                              <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                                {formikHandling.errors.lastName}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          Email address <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="email"
                            placeholder="Email address"
                            value={formikHandling.values.email}
                            onChange={formikHandling.handleChange}
                            className="outline-none w-full block bg-white border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold text-black placeholder:font-proxima-regular"
                          />
                          {formikHandling.errors.email &&
                            formikHandling.touched.email && (
                              <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                                {formikHandling.errors.email}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          Phone <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <PhoneInput
                            placeholder="Phone"
                            countries={["IN"]}
                            defaultCountry="IN"
                            countryCallingCodeEditable={false}
                            className="border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold text-black"
                            value={formikHandling.values.phone}
                            onChange={(e) => {
                              formikHandling.setFieldValue("phone", e);
                            }}
                          />
                          {formikHandling.errors.phone &&
                            formikHandling.touched.phone && (
                              <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                                {formikHandling.errors.phone}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          Message <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <textarea
                            rows={2}
                            name="message"
                            placeholder="Type your message"
                            className="w-full block border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold placeholder:font-proxima-regular text-black"
                            value={formikHandling.values.message}
                            onChange={formikHandling.handleChange}
                          />
                          {formikHandling.errors.message &&
                            formikHandling.touched.message && (
                              <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                                {formikHandling.errors.message}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    disabled={formLoading}
                    type={formLoading ? "button" : "submit"}
                    className="mt-5 shadow-none hover:shadow-none bg-logo rounded-md text-sm font-proxima-semibold text-white px-5 normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                  >
                    {formLoading ? "Please wait.." : "Submit details"}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* TESTIMONIALS */}
      <motion.div
        variants={animation}
        className="md:px-4 lg:px-0 py-5 xl:py-14 lg:py-14"
      >
        <div className="container">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2">
            <div className="col-span-1">
              <div className="w-full xl:w-4/5 lg:w-4/5 mt-5">
                <h5 className="text-xl font-proxima-semibold text-logo">
                  Testimonials
                </h5>
                <h2 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold text-black tracking-tight">
                  Hear what our clients say
                </h2>
                <p className="mt-2 text-lg xl:text-xl lg:text-xl font-proxima-regular text-grey leading-snug">
                  We don't just say it, we put it in action and take pride in
                  delivering results. Our mission is to empower traders with the
                  best journaling tool they need to thrive to manage their
                  trades and analyze their trading performance.
                </p>
                <Link to="/pricing">
                  <Button className="mt-8 shadow-none hover:shadow-none bg-logo rounded-lg text-base font-proxima-semibold text-white px-5 py-3 flex items-center gap-2 normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                    Start Journaling Today
                    <MdOutlineArrowOutward className="w-5 h-5 text-white" />
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col-span-1">
              <div className="w-full overflow-hidden relative h-[80vh] xl:h-testimonialBox lg:h-testimonialBox">
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 py-5">
                  <div className="col-span-1">
                    {testimonials.slice(0, 3).map((data) => (
                      <React.Fragment key={data.id}>
                        <TestimonialBox
                          name={data.name}
                          designation={data.designation}
                          testimonialText={data.testimonialText}
                          value={data.value}
                          date={data.date}
                          profile={data.profile}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="col-span-1">
                    {testimonials.slice(3, 5).map((data) => (
                      <React.Fragment key={data.id}>
                        <TestimonialBox
                          name={data.name}
                          designation={data.designation}
                          testimonialText={data.testimonialText}
                          value={data.value}
                          date={data.date}
                          profile={data.profile}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </div>

                {/* GRADIENT */}
                <div className="absolute left-0 -bottom-1 w-full h-9 testimonial-gradient" />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default ContactUs;
