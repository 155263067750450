/* PACKAGES */
import { motion } from "framer-motion";

/* ANIMATION */
import { animation, containerAnimation } from "data/animation";

const RefundPolicy = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* BANNER */}
      <motion.div variants={animation} className="py-10 bg-[#f6f5f0]">
        <div className="container mx-auto">
          <h1 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold text-black tracking-tight">
            Cancellation and Refund Policy
          </h1>
        </div>
      </motion.div>

      {/* CONTENT */}
      <motion.div variants={animation} className="py-10">
        <div className="container">
          <div className="p-0">
            <p className="text-base font-proxima-regular text-grey mt-2">
              At TACTICAL TRADING TECHNOLOGIES LLP, we strive to ensure your
              satisfaction with our software products. If you are not completely
              satisfied with your purchase, we offer a straightforward refund
              policy to make things right.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-semibold text-black">
              Refund Eligibility:
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You are eligible for a refund under the following conditions:
            </p>
            <ul className="ml-8 mt-3 list-disc">
              <li className="text-base font-proxima-regular text-grey">
                <span className="font-proxima-bold text-black">Timeframe:</span>{" "}
                Refund requests must be made within 7 days from the date of
                purchase.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-3">
                <span className="font-proxima-bold text-black">
                  Reason for Refund:
                </span>{" "}
                Refunds will be considered for the following reasons:
                <ul className="ml-8 mt-3 list-decimal">
                  {/* <li className="text-base font-proxima-regular text-grey">
                    We are not responsible for continued subscription if a
                    customer forget to cancel their account. It is the customers
                    responsibility to confirm the cancellation.
                  </li> */}
                  <li className="text-base font-proxima-regular text-grey mt-3">
                    Technical issues that prevent you from using the software
                    effectively, despite reasonable attempts to resolve the
                    problem with our support team.
                  </li>
                  <li className="text-base font-proxima-regular text-grey mt-3">
                    Unauthorized purchases or errors in subscription processing.
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="mt-10">
            <h5 className="text-xl font-proxima-semibold text-black">
              Refund Procedure:
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              To request a refund, please follow these steps:
            </p>
            <ul className="ml-8 mt-3 list-disc">
              <li className="text-base font-proxima-regular text-grey">
                <span className="font-proxima-bold text-black">
                  Contact Support:
                </span>{" "}
                Send an email to{" "}
                <a href="mailto:contact@tradetalez.com" className="text-logo">
                  contact@tradetalez.com
                </a>{" "}
                stating your reason for requesting a refund and providing your
                order details (such as order number, email used for purchase,
                etc.).
              </li>
              <li className="text-base font-proxima-regular text-grey mt-3">
                <span className="font-proxima-bold text-black">
                  Verification:
                </span>{" "}
                Our support team will review your request and may contact you
                for further information to verify your purchase and the reason
                for the refund.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-3">
                <span className="font-proxima-bold text-black">
                  Processing:
                </span>{" "}
                Once your request is approved, we will initiate the refund
                process. Refunds will be issued to the original payment method
                used during the purchase.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-3">
                <span className="font-proxima-bold text-black">
                  Confirmation:
                </span>{" "}
                You will receive a confirmation email once your refund has been
                processed. Please note that it may take 5-10 business days for
                the refund to appear on your account, depending on your bank or
                credit card issuer.
              </li>
            </ul>
          </div>

          <div className="mt-10">
            <h5 className="text-xl font-proxima-semibold text-black">
              Exceptions:
            </h5>
            <ul className="ml-8 mt-3 list-disc">
              <li className="text-base font-proxima-regular text-grey">
                <span className="font-proxima-bold text-black">
                  Outside Refund Period:
                </span>{" "}
                Refund requests made after 7 days from the date of purchase may
                not be considered unless there are exceptional circumstances,
                such as prolonged technical issues that were reported within the
                refund period.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-3">
                <span className="font-proxima-bold text-black">
                  Subscription Renewals:
                </span>{" "}
                Refunds for subscription renewals are generally not granted
                unless there was a billing error or unauthorized renewal.
              </li>
            </ul>
          </div>

          <div className="mt-5">
            <p className="text-base font-proxima-regular text-grey ">
              We are not responsible for continued subscription if a customer
              forgets to cancel their account. It is the customers
              responsibility to confirm the cancellation.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-semibold text-black">
              Contact Us:
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              If you have any questions about our refund policy or need
              assistance with a refund request, please contact our support team
              at{" "}
              <a href="mailto:contact@tradetalez.com" className="text-logo">
                contact@tradetalez.com
              </a>
              . We are here to help resolve any issues promptly and ensure your
              satisfaction with our products and services.
            </p>
          </div>

          <div className="mt-10">
            <h5 className="text-xl font-proxima-semibold text-black">
              Policy updates:
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              TACTICAL TRADING TECHNOLOGIES LLP reserves the right to update or
              modify this refund policy at any time without prior notice. Please
              check our website or contact support for the most current
              information regarding our refund policy.
            </p>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default RefundPolicy;
