/* PACKAGES */
import { motion } from "framer-motion";

/* ANIMATION */
import { animation, containerAnimation } from "data/animation";

const PrivacyPolicy = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* BANNER */}
      <motion.div variants={animation} className="py-10 bg-[#f6f5f0]">
        <div className="container mx-auto">
          <h1 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold text-black tracking-tight">
            Privacy Policy
          </h1>
        </div>
      </motion.div>

      {/* CONTENT */}
      <motion.div variants={animation} className="py-10">
        <div className="container">
          <div className="p-0">
            <p className="text-base font-proxima-regular text-grey mt-2">
              This privacy notice discloses the privacy practices for
              TradeTalez.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data. We use your data
              to provide and improve the Service. By using the Service, you
              agree to the collection and use of information in accordance with
              this policy.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              This privacy notice applies solely to information collected by
              this web site. It will notify you of the following:
            </p>
            <ul className="ml-8 mt-3 list-decimal">
              <li className="text-base font-proxima-regular text-grey mt-2">
                What personally identifiable information is collected from you
                through the web site, how it is used and with whom it may be
                shared.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                What choices are available to you regarding the use of your
                data.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                The security procedures in place to protect the misuse of your
                information.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                How you can correct any inaccuracies in the information.
              </li>
            </ul>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              Information Collection, Use, and Sharing
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              We are the sole owners of the information collected on this site.
              We only have access to/collect information that you voluntarily
              give us when using the site. We will not sell or rent this
              information to anyone.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              We use your information to provide the services of the site. We
              will not share your information with any third party outside of
              our organization, other than as necessary to fulfill your request.
              Specifically:
            </p>
            <ul className="ml-8 mt-3 list-disc">
              <li className="text-base font-proxima-regular text-grey mt-2">
                If you explicitly share a trade publicly, certain information
                about your trade will be made available on the site (you can see
                examples by looking at other shared trades on the site). If you
                do not wish this information to be public, please do not share
                trades.
              </li>
            </ul>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Unless you ask us not to, we may contact you via email in the
              future to tell you about specials, new products or services, or
              changes to this privacy policy.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              We collect statistical information on web traffic using a third
              party web analytics service; this may use a cookie on your
              computer, but no user-identifiable information is ever shared with
              this third party other than "typical" web analytics such as IP
              address, browser, etc.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              In no event will your individual data be made available to anyone,
              nor will any personally identifiable information be made available
              or associated with any of the data.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              Your Access to and Control Over Information
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              You may opt out of any future contacts from us at any time. You
              can do the following at any time by contacting us via email at{" "}
              <a
                href="mailto:contact@tradetalez.com"
                className="text-logo font-proxima-semibold"
              >
                contact@tradetalez.com
              </a>
            </p>
            <ul className="ml-8 mt-3 list-disc">
              <li className="text-base font-proxima-regular text-grey mt-2">
                Have us delete any data we have about you.
              </li>
              <li className="text-base font-proxima-regular text-grey mt-2">
                Express any concern you have about our use of your data.
              </li>
            </ul>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black tracking-tight">
              Security
            </h5>
            <p className="text-base font-proxima-regular text-grey mt-2">
              We take precautions to protect your information. When you submit
              sensitive information via the website, your information is
              protected both online and offline.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              Wherever we collect sensitive information (such as your trade
              data), that information is encrypted and transmitted to us in a
              secure way.
            </p>
            <p className="text-base font-proxima-regular text-grey mt-2">
              If you feel that we are not abiding by this privacy policy, please
              contact us immediately via email at{" "}
              <a
                href="contact@tradetalez.com"
                className="text-logo font-proxima-semibold"
              >
                contact@tradetalez.com
              </a>
            </p>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default PrivacyPolicy;
