import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Collapse } from "@material-tailwind/react";

/* ANIMATION */
import { animation } from "data/animation";

/* ICONS */
import { BiMenuAltRight } from "react-icons/bi";
import { FaInstagram } from "react-icons/fa6";

const Header = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <motion.div
        variants={animation}
        className="py-0 xl:py-[0.6vh] lg:py-[0.6vh] newsletter-gradient"
      >
        <div className="container mx-auto">
          <div className="block xl:flex lg:flex items-center justify-center xl:justify-between lg:justify-between">
            <div className="flex items-center justify-center gap-3">
              <p className="text-base font-proxima-regular text-white">
                Follow us on
              </p>
              <a
                href="https://www.instagram.com/tradetalez?igsh=MWc3bW1hdTRpZDdxZA=="
                target="_blank"
                rel="noreferrer"
              >
                <Button className="shadow-none hover:shadow-none rounded-full p-2 bg-transparent hover:bg-white hover:bg-opacity-10">
                  <FaInstagram className="w-5 h-5 text-white" />
                </Button>
              </a>
            </div>
            <div className="flex items-center justify-center gap-3">
              <p className="text-base font-proxima-regular text-white">
                Chat with us at
              </p>
              <a
                href="mailto:contact@tradetalez.com"
                target="_blank"
                className="text-base font-proxima-semibold text-logo"
                rel="noreferrer"
              >
                contact@tradetalez.com
              </a>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.header
        variants={animation}
        className="py-1 xl:py-2 lg:py-2 border-b border-greyBorder bg-white z-50 main-header"
      >
        <div className="container">
          <div className="flex items-center justify-between">
            <Link to="/">
              <img
                src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/logo.webp"
                className="w-[12vh] xl:w-footerLogo lg:w-footerLogo h-auto cursor-pointer"
                alt="Trade Talez"
              />
              <p className="text-base font-proxima-semibold text-black mt-2 leading-none">
                Record, Reflect and Rise
              </p>
            </Link>

            {/* NAVIGATION MENU FOR DESKTOP */}
            <div className="hidden xl:flex lg:flex items-center gap-12">
              {/* ABOUT US */}
              <Link
                to="/"
                className="block text-base font-proxima-semibold text-black hover:text-logo transition-all duration-300 ease-in-out cursor-pointer"
              >
                About Us
              </Link>

              {/* OUR FEATURES */}
              <Link
                to="/our-features"
                className={`block text-base font-proxima-semibold ${
                  location.pathname === "/our-features"
                    ? "text-logo"
                    : "text-black"
                } hover:text-logo transition-all duration-300 ease-in-out cursor-pointer relative`}
              >
                Our Features
                {/* UNDERLINE */}
                {location.pathname === "/our-features" && (
                  <div className="absolute top-6 w-full left-0 h-[0.2vh] bg-logo rounded-lg" />
                )}
              </Link>

              {/* PRICING */}
              <Link
                to="/pricing"
                className={`block text-base font-proxima-semibold ${
                  location.pathname === "/pricing" ? "text-logo" : "text-black"
                } hover:text-logo transition-all duration-300 ease-in-out cursor-pointer relative`}
              >
                Pricing
                {/* UNDERLINE */}
                {location.pathname === "/pricing" && (
                  <div className="absolute top-6 w-full left-0 h-[0.2vh] bg-logo rounded-lg" />
                )}
              </Link>

              {/* BROKER SUPPORT */}
              <Link
                to="/broker-support"
                className={`block text-base font-proxima-semibold ${
                  location.pathname === "/broker-support"
                    ? "text-logo"
                    : "text-black"
                } hover:text-logo transition-all duration-300 ease-in-out cursor-pointer relative`}
              >
                Broker Support
                {/* UNDERLINE */}
                {location.pathname === "/broker-support" && (
                  <div className="absolute top-6 w-full left-0 h-[0.2vh] bg-logo rounded-lg" />
                )}
              </Link>

              {/* RESOURCE CENTER */}
              <Link
                to="/resource-center"
                className={`block text-base font-proxima-semibold ${
                  location.pathname === "/resource-center"
                    ? "text-logo"
                    : "text-black"
                } hover:text-logo transition-all duration-300 ease-in-out cursor-pointer relative`}
              >
                Resource Center
                {/* UNDERLINE */}
                {location.pathname === "/resource-center" && (
                  <div className="absolute top-6 w-full left-0 h-[0.2vh] bg-logo rounded-lg" />
                )}
              </Link>

              {/* CONTACT US */}
              <Link
                to="/contact-us"
                className={`block text-base font-proxima-semibold ${
                  location.pathname === "/contact-us"
                    ? "text-logo"
                    : "text-black"
                } hover:text-logo transition-all duration-300 ease-in-out cursor-pointer relative`}
              >
                Contact Us
                {/* UNDERLINE */}
                {location.pathname === "/contact-us" && (
                  <div className="absolute top-6 w-full left-0 h-[0.2vh] bg-logo rounded-lg" />
                )}
              </Link>

              {/* WAITING LIST */}
              <div className="flex items-center gap-2">
                <a
                  href="https://app.tradetalez.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="block"
                >
                  <Button className="shadow-none hover:shadow-none bg-transparent border border-logo rounded-md text-logo font-proxima-semibold text-base hover:bg-logo hover:bg-opacity-10 transition-all duration-300 ease-in-out normal-case py-3 px-5">
                    Login
                  </Button>
                </a>
                <Link to="/pricing" className="block">
                  <Button className="shadow-none hover:shadow-none bg-logo border border-logo rounded-md text-white font-proxima-semibold text-base hover:bg-logo hover:bg-opacity-80 transition-all duration-300 ease-in-out normal-case py-3 px-6">
                    Start Journaling
                  </Button>
                </Link>
              </div>
            </div>

            {/* MENU BUTTON FOR MOBILE */}
            <Button
              className="block xl:hidden lg:hidden border border-greyBorder rounded-full p-2 bg-white shadow-none hover:shadow-none"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <BiMenuAltRight className="w-6 h-6 text-black" />
            </Button>
          </div>
        </div>

        {/* MOBILE MENU */}
        <Collapse
          open={menuOpen}
          placement="bottom"
          animate={{
            mount: { opacity: 1, y: 0 },
            unmount: { opacity: 0, y: 25 },
          }}
        >
          <div className="p-5 bg-white">
            <Link
              to="/"
              onClick={() => setMenuOpen(!menuOpen)}
              className="block text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out"
            >
              About Us
            </Link>
            <Link
              to="/our-features"
              onClick={() => setMenuOpen(!menuOpen)}
              className="block text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out mt-3"
            >
              Our Features
            </Link>
            <Link
              to="/pricing"
              onClick={() => setMenuOpen(!menuOpen)}
              className="block text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out mt-3"
            >
              Pricing
            </Link>
            <Link
              to="/broker-support"
              onClick={() => setMenuOpen(!menuOpen)}
              className="block text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out mt-3"
            >
              Broker Support
            </Link>
            <Link
              to="/resource-center"
              onClick={() => setMenuOpen(!menuOpen)}
              className="block text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out mt-3"
            >
              Resource Center
            </Link>
            <Link
              to="/contact-us"
              onClick={() => setMenuOpen(!menuOpen)}
              className="block text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out mt-3"
            >
              Contact Us
            </Link>
            <div className="flex items-center gap-2 mt-5">
              <a
                href="https://app.tradetalez.com/"
                target="_blank"
                rel="noreferrer"
                className="block"
              >
                <Button className="shadow-none hover:shadow-none bg-transparent border border-logo rounded-md text-logo font-proxima-semibold text-base hover:bg-logo hover:bg-opacity-10 transition-all duration-300 ease-in-out normal-case py-3 px-5">
                  Login
                </Button>
              </a>
              <Link
                to="/pricing"
                className="block"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <Button className="shadow-none hover:shadow-none bg-logo border border-logo rounded-md text-white font-proxima-semibold text-base hover:bg-logo hover:bg-opacity-80 transition-all duration-300 ease-in-out normal-case py-3 px-6">
                  Start Journaling
                </Button>
              </Link>
            </div>
          </div>
        </Collapse>
      </motion.header>
    </>
  );
};

export default Header;
