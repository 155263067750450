const TABLE_HEAD = ["Broker", "CSV", "Autosync"];

const TABLE_ROWS = [
  {
    name: "AngelOne",
    csv: true,
    autosync: true,
  },
  {
    name: "Dhan",
    csv: false,
    autosync: true,
  },
  {
    name: "Fyers",
    csv: false,
    autosync: true,
  },
  {
    name: "Motilal Oswal",
    csv: false,
    autosync: true,
  },
  {
    name: "Upstox",
    csv: true,
    autosync: true,
  },
  {
    name: "Zerodha",
    csv: true,
    autosync: true,
  },
];

export { TABLE_HEAD, TABLE_ROWS };
