import React from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";

/* ANIMATION */
import { animation } from "data/animation";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";

/* DATA */
import testimonials from "data/testimonials";

const Testimonials = () => {
  /* TESTIMONIAL BOX */
  const TestimonialBox = (props) => {
    return (
      <div className="w-full bg-white border border-greyBorder rounded-lg shadow p-5 first:mt-0 mt-5">
        {/* NAME & DESIGNATION */}
        <div className="flex items-center gap-3">
          <img
            src={props.profile}
            className="w-10 h-10 rounded-full object-cover"
            alt={props.name}
          />
          <div className="p-0">
            <p className="text-base font-proxima-semibold text-black">
              {props.name}
            </p>
            <p className="text-sm font-proxima-regular text-grey">
              {props.designation}
            </p>
          </div>
        </div>

        {/* CONTENT */}
        <div className="mt-3">
          <p className="text-sm font-proxima-regular text-black">
            {props.testimonialText}
          </p>
          <p className="mt-3 text-sm font-proxima-regular text-grey">
            {props.date}
          </p>
        </div>
      </div>
    );
  };

  return (
    <motion.div
      variants={animation}
      className="md:px-4 lg:px-0 py-5 xl:py-14 lg:py-14"
    >
      <div className="container">
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2">
          <div className="col-span-1">
            <div className="w-full xl:w-4/5 lg:w-4/5 mt-5">
              <h5 className="text-xl font-proxima-semibold text-logo">
                Testimonials
              </h5>
              <h2 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold text-black tracking-tight">
                Hear what our clients say
              </h2>
              <p className="mt-2 text-lg xl:text-xl lg:text-xl font-proxima-regular text-grey leading-snug">
                We don't just say it, we put it in action and take pride in
                delivering results. Our mission is to empower traders with the
                best journaling tool they need to thrive to manage their trades
                and analyze their trading performance.
              </p>
              <Link to="/pricing">
                <Button className="mt-8 shadow-none hover:shadow-none bg-logo rounded-lg text-base font-proxima-semibold text-white px-5 py-3 flex items-center gap-2 normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                  Start Journaling Today
                  <MdOutlineArrowOutward className="w-5 h-5 text-white" />
                </Button>
              </Link>
            </div>
          </div>
          <div className="col-span-1">
            <div className="w-full overflow-hidden relative h-[80vh] xl:h-testimonialBox lg:h-testimonialBox">
              <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 py-5">
                <div className="col-span-1">
                  {testimonials.slice(0, 3).map((data) => (
                    <React.Fragment key={data.id}>
                      <TestimonialBox
                        name={data.name}
                        designation={data.designation}
                        testimonialText={data.testimonialText}
                        value={data.value}
                        date={data.date}
                        profile={data.profile}
                      />
                    </React.Fragment>
                  ))}
                </div>
                <div className="col-span-1">
                  {testimonials.slice(3, 5).map((data) => (
                    <React.Fragment key={data.id}>
                      <TestimonialBox
                        name={data.name}
                        designation={data.designation}
                        testimonialText={data.testimonialText}
                        value={data.value}
                        date={data.date}
                        profile={data.profile}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>

              {/* GRADIENT */}
              <div className="absolute left-0 -bottom-1 w-full h-9 testimonial-gradient" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Testimonials;
