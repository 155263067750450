import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";

/* USER DEFINED COMPONENTS */
import FrequentlyAskedQuestions from "components/faqs";

/* ANIMATION */
import { animation, containerAnimation } from "data/animation";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

/* ICONS */
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";

/* DATA */
import { categories } from "data/ResourceCenter";
import Testimonials from "components/testimonials";

const ResourceCenter = () => {
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [importTrades, setImportTrades] = useState();
  const [openImportTrade, setOpenImportTrade] = useState(false);

  const renderNestedSubItems = (subItems) => {
    return subItems.map((nestedSubItem, nestedSubIndex) => (
      <div key={nestedSubIndex}>
        <div className="flex items-start gap-3 mt-3">
          <PiPaperPlaneRightFill className="w-4 h-4 text-logo flex-shrink-0" />
          <p className="text-base font-proxima-regular text-grey">
            {nestedSubItem.title}
          </p>
        </div>
      </div>
    ));
  };

  const ResourceBox = (props) => {
    return (
      <div className="group w-full bg-white border border-greyBorder rounded-lg shadow hover:shadow-md p-5 cursor-pointer transition-all duration-300 ease-in-out">
        <div className="w-16 h-16 bg-greyBg group-hover:bg-logo rounded-full flex items-center justify-center transition-all duration-300 ease-in-out">
          <props.icon className="w-7 h-7 text-black group-hover:text-white" />
        </div>
        <div className="mt-3">
          <h5 className="text-xl font-proxima-bold text-black tracking-tight">
            {props.title}
          </h5>
          <p className="text-base font-proxima-regular text-grey mt-2">
            {props.description}
          </p>
        </div>
      </div>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* BANNER */}
      <motion.div
        variants={animation}
        className="pt-5 xl:pt-12 lg:pt-12 relative"
      >
        <div className="container relative z-50">
          <div className="w-full xl:w-[90%] lg:w-[90%] mx-auto">
            {/* HEADING */}
            <div className="p-0">
              <h1 className="text-3xl xl:text-[5.5vh] lg:text-[5.5vh] font-helvetica-bold tracking-tight text-white text-center leading-snug">
                TradeTalez Resource Center
              </h1>
              <h2 className="text-lg xl:text-2xl lg:text-2xl font-proxima-regular text-white text-opacity-70 text-center mt-5">
                Welcome to TradeTalez, your automated digital trading journal.
                This video is recorded to walk you through each feature of our
                app, ensuring a smooth and efficient experience even if you're
                new to trading.
              </h2>
            </div>

            {/* YOUTUBE VIDEO IFRAME */}
            <div className="mt-5 xl:mt-10 lg:mt-10">
              <div className="w-full xl:w-4/5 lg:w-4/5 mx-auto">
                <iframe
                  src="https://www.youtube.com/embed/ojisxFRQWU0?si=4jxiD4gYLFH7-V_z"
                  title="Know more about TradeTalez"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  className="w-full h-[30vh] xl:h-[60vh] lg:h-[60vh] rounded-xl mx-auto"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* BACKGROUND GRADIENT */}
        <div className="newsletter-gradient h-[50vh] absolute top-0 left-0 w-full">
          {/* TOP RIGHT */}
          <div className="absolute top-0 right-0 overflow-hidden">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/banner-circle.webp"
              className="w-[15vh] xl:w-[27vh] lg:w-[27vh] h-auto banner-shape hidden xl:block lg:block"
              alt="Simplify, Analyze, and Optimize Your Trading Experience"
            />
          </div>

          {/* BOTTOM LEFT */}
          <div className="absolute bottom-0 left-0 overflow-hidden">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/banner-circle.webp"
              className="w-[15vh] xl:w-[27vh] lg:w-[27vh] h-auto banner-shape-bottom hidden xl:block lg:block"
              alt="Simplify, Analyze, and Optimize Your Trading Experience"
            />
          </div>
        </div>
      </motion.div>

      {/* RESOURCES LIST */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container">
          <div className="p-0">
            <h5 className="text-xl xl:text-2xl lg:text-2xl font-proxima-semibold text-logo">
              TradeTalez Docs
            </h5>
            <h2 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold tracking-tight text-black">
              Comprehensive User Guide
            </h2>
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 items-center mt-10">
            {/* IMPORT TRADES */}
            <div
              className="col-span-1"
              onClick={() => {
                setImportTrades(categories[0]);
                setOpenImportTrade(!openImportTrade);
              }}
            >
              <ResourceBox
                icon={categories[0].icon}
                title={categories[0].title}
                description={categories[0].description}
              />
            </div>

            {/* OTHER ROW ONE OPTIONS */}
            {categories.slice(1, 3).map((item) => (
              <div
                className="col-span-1"
                key={item.id}
                onClick={() => {
                  setSelectedCategory(item);
                  setOpen(!open);
                }}
              >
                <ResourceBox
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              </div>
            ))}
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5 items-center mt-5">
            {categories.slice(3, 7).map((item) => (
              <div
                className="col-span-1"
                key={item.id}
                onClick={() => {
                  setSelectedCategory(item);
                  setOpen(!open);
                }}
              >
                <ResourceBox
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <FrequentlyAskedQuestions />

      {/* TESTIMONIALS */}
      <Testimonials />

      {/* IMPORT TRADES DIALOG */}
      {importTrades && (
        <Dialog
          open={openImportTrade}
          handler={() => setOpenImportTrade(!openImportTrade)}
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          size="lg"
          className="max-w-[95%] xl:max-w-auto lg:max-w-auto"
        >
          <DialogBody className="p-0">
            <div className="px-5 py-3 border-b border-greyBorder">
              <div className="flex items-center justify-between">
                <div className="p-0">
                  <h5 className="text-xl font-proxima-bold text-black">
                    Import Trades
                  </h5>
                </div>
                <Button
                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-10"
                  onClick={() => setOpenImportTrade(!openImportTrade)}
                >
                  <IoClose className="w-4 h-4 text-grey" />
                </Button>
              </div>
            </div>
            <div className="p-5 max-h-[70vh] xl:max-h-auto lg:max-h-auto overflow-auto hide-scrollbar">
              <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                {importTrades?.items?.map((item, index) => (
                  <div
                    className={`${
                      index === 2
                        ? "col-span-2"
                        : "col-span-2 xl:col-span-1 lg:col-span-1"
                    }`}
                    key={index}
                  >
                    <div className="w-full bg-greyBg bg-opacity-50 border border-greyBorder p-5 rounded-md">
                      <h1 className="text-lg font-proxima-bold text-black">
                        {item.title}
                      </h1>
                      <div className="mt-2">
                        {item.subItems.map((subItem, index) => (
                          <>
                            <div className="flex items-start gap-3 mt-4 first:mt-0">
                              <FaCircleCheck className="w-5 h-5 text-logo flex-shrink-0 mt-1" />
                              <p className="text-base font-proxima-regular text-grey">
                                <span className="font-proxima-bold">
                                  {subItem.title}
                                </span>{" "}
                                {subItem.desc}
                              </p>
                            </div>
                            {subItem?.nestedItems && (
                              <ol className="pl-14 list-decimal font-proxima-regular">
                                {subItem?.nestedItems?.map((item) => (
                                  <li key={item.id} className="mt-2">
                                    <p className="text-base font-proxima-regular text-grey">
                                      <span className="font-proxima-semibold">
                                        {item.title}
                                      </span>{" "}
                                      {item.desc}
                                    </p>
                                  </li>
                                ))}
                              </ol>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </DialogBody>
        </Dialog>
      )}

      {/* OTHER OPTIONS DIALOG */}
      {selectedCategory && (
        <Dialog
          open={open}
          handler={() => setOpen(!open)}
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          size={
            selectedCategory?.title === "Trade Log" ||
            selectedCategory?.title === "Reports" ||
            selectedCategory?.title === "Setup Management" ||
            selectedCategory?.title === "Clear Trades"
              ? "sm"
              : "lg"
          }
          className="max-w-[95%] xl:max-w-auto lg:max-w-auto"
        >
          <DialogBody className="p-0">
            <div className="px-5 py-3 border-b border-greyBorder">
              <div className="flex items-center justify-between">
                <div className="p-0 w-4/5">
                  <h5 className="text-xl font-proxima-bold text-black">
                    {selectedCategory?.title}
                  </h5>
                </div>
                <Button
                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-10"
                  onClick={() => setOpen(!open)}
                >
                  <IoClose className="w-4 h-4 text-grey" />
                </Button>
              </div>
            </div>
            <div className="p-5 max-h-[70vh] xl:max-h-auto lg:max-h-auto overflow-auto hide-scrollbar">
              {selectedCategory.items.map((item, index) => (
                <div key={index} className="mt-3 first:mt-0">
                  <div className="flex items-start gap-3">
                    <FaCircleCheck className="w-5 h-5 text-logo flex-shrink-0 mt-1" />
                    <p className="text-base font-proxima-regular text-grey">
                      <span className="font-proxima-bold">{item.title}</span>{" "}
                      {item.desc}
                    </p>
                  </div>
                  {item.subItems.length > 0 && (
                    <div className="pl-14">
                      <ol className="list-decimal gap-2">
                        {item.subItems.map((subItem, subIndex) => (
                          <li
                            key={subIndex}
                            className="index mt-3 font-proxima-regular"
                          >
                            <p className="text-base font-proxima-regular text-grey">
                              <span className="font-proxima-bold">
                                {subItem.title}
                              </span>{" "}
                              {subItem.desc}
                            </p>
                            {subItem.subItems && (
                              <div className="pl-5">
                                {renderNestedSubItems(subItem.subItems)}
                              </div>
                            )}
                          </li>
                        ))}
                      </ol>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </DialogBody>
        </Dialog>
      )}
    </motion.section>
  );
};

export default ResourceCenter;
