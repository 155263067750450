import React, { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-number-input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

/* USER DEFINED COMPONENTS */
import PricingBox from "components/pricing";
import FrequentlyAskedQuestions from "components/faqs";
import Testimonials from "components/testimonials";

/* ICONS */
import { IoClose } from "react-icons/io5";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";

/* ANIMATION */
import { animation, containerAnimation } from "data/animation";

/* SAMPLE DATA */
import pricingPlans from "data/pricing";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* APIS */
import {
  checkSubscription,
  contactUsAPI,
  getProfile,
  registerAPI,
} from "service/api";

/* VALIDATION SCHEMA */
const formValidation = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .strict(true, "First name is required")
    .min(1, "First name is required")
    .required("First name is required."),
  lastName: yup
    .string()
    .trim()
    .strict(true, "Last name is required")
    .min(1, "Last name is required")
    .required("Last name is required."),
  email: yup
    .string()
    .email()
    .trim()
    .strict(true, "Email Address is required")
    .min(1, "Email Address is required")
    .required("Email Address is required."),
  phone: yup
    .string()
    .trim()
    .strict(true, "Phone Number is required")
    .min(1, "Phone Number is required")
    .required("Phone Number is required."),
  message: yup
    .string()
    .trim()
    .strict(true, "Message is required.")
    .min(1, "Message is required.")
    .required("Message is required."),
});
/* VALIDATION SCHEMA */
const registerFormValidation = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .strict(true, "First name is required")
    .min(1, "First name is required")
    .required("First name is required."),
  lastName: yup
    .string()
    .trim()
    .strict(true, "Last name is required")
    .min(1, "Last name is required")
    .required("Last name is required."),
  // userName: yup
  //   .string()
  //   .trim()
  //   .strict(true, "Last name is required")
  //   .min(1, "Last name is required")
  //   .required("User name is required."),
  email: yup
    .string()
    .email()
    .trim()
    .strict(true, "Email Address is required")
    .min(1, "Email Address is required")
    .required("Email Address is required."),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
});
const Pricing = () => {
  /* TAB STATES */
  const [activeTab, setActiveTab] = useState("Monthly");

  /* PRICING STATES */
  const [selectedPlan, setSelectedPlan] = useState({
    name: "",
    description: "",
    features: [],
    monthlyPrice: "",
    originalMonthlyPrice: "",
    monthlyDiscount: "",
    annualPrice: "",
    originalAnnualPrice: "",
    annualDiscount: "",
    isMonthly: true,
  });
  console.log("selectedPlan: ", selectedPlan);

  /* DIALOG STATES */
  const [contactDialog, setContactDialog] = useState(false);
  const [pricingDialog, setPricingDialog] = useState(false);
  const [passwordToggle, setPasswordToggle] = useState(false);

  /* LOADING STATES */
  const [formLoading, setFormLoading] = useState(false);

  /* CONTACT US FORM HANDLING */
  const formikHandling = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      const obj = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        message: values.message,
      };
      setFormLoading(true);
      contactUsAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Thank you for reaching out to us. We will get back to you in 1-2 business days."
            );
          } else {
            toast.error(res?.message);
          }
          setFormLoading(false);
          setContactDialog(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formikHandling.handleReset();
          setFormLoading(false);
        });
    },
  });
  /* REGISTER HANDLING */
  const registerFormikHandling = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      // userName: "",
      password: "",
      email: "",
    },
    validationSchema: registerFormValidation,
    onSubmit: (values) => {
      const obj = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        // userName: values.userName,
        subscriptionPlane: selectedPlan?.id,
      };
      setFormLoading(true);
      registerAPI(obj)
        .then((res) => {
          if (res?.data?.subscription?.data) {
            let windowP = window.open(
              res?.data?.subscription?.data,
              "Payment",
              "height=800,width=1200"
            );
            if (window.focus) {
              windowP.focus();
            }

            let timer = setInterval(function () {
              if (windowP.closed) {
                clearInterval(timer);

                checkSubscription(
                  { subscriptionId: res?.subscriptionId },
                  res?.data?.token
                )
                  .then((obj) => {
                    if (obj?.status) {
                      getProfile(res?.data?.token)
                        .then((res) => {
                          if (res?.data?.isSubscribed) {
                            window.location.href =
                              "https://app.tradetalez.com/";
                          }
                        })
                        .catch((e) => {
                          console.log("Error: ", e);
                        });
                    }
                  })
                  .catch((err) => {
                    if (err?.response?.data?.message) {
                      toast.error(err.response?.data?.message);
                    }
                  });
              }
            }, 1000);
          }
          setFormLoading(false);
          setContactDialog(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formikHandling.handleReset();
          setFormLoading(false);
        });
    },
  });

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* BANNER */}
      <motion.div variants={animation} className="pt-14 relative">
        <div className="container relative z-50">
          <div className="w-full xl:w-[45%] lg:w-[45%] mx-auto">
            <h1 className="text-3xl font-helvetica-bold tracking-tight text-white text-center leading-snug block xl:hidden lg:hidden">
              Our pricing is simple with no hidden fees
            </h1>
            <h1 className="text-[5.5vh] font-helvetica-bold tracking-tight text-white text-center leading-snug hidden xl:block lg:block">
              Our pricing is simple with no hidden fees
            </h1>
            <h2 className="text-xl font-proxima-regular text-white text-opacity-60 tracking-tight text-center mt-5">
              Pricing plans for traders at every stage of growth.
            </h2>

            {/* MONTHLY AND ANNUALLY SWITCHER */}
            <div className="mt-5 xl:mt-10 lg:mt-10">
              <div className="w-fit flex items-center justify-center gap-1 bg-white border border-greyBorder rounded-lg p-1 mx-auto">
                <Button
                  className={`shadow-none hover:shadow-none ${
                    activeTab === "Monthly"
                      ? "bg-logo font-proxima-semibold text-white border border-logo"
                      : "bg-transparent font-proxima-semibold text-grey"
                  } text-base rounded-md px-8 py-2 normal-case transition-all duration-300 ease-in-out`}
                  onClick={() => setActiveTab("Monthly")}
                >
                  Monthly
                </Button>
                <Button
                  className={`shadow-none hover:shadow-none ${
                    activeTab === "Annually"
                      ? "bg-logo font-proxima-semibold text-white border border-logo"
                      : "bg-transparent font-proxima-semibold text-grey"
                  } text-base rounded-md px-8 py-2 normal-case transition-all duration-300 ease-in-out`}
                  onClick={() => setActiveTab("Annually")}
                >
                  Annually
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* BACKGROUND GRADIENT */}
        <div className="newsletter-gradient h-[60vh] xl:h-mainBanner lg:h-mainBanner absolute top-0 left-0 w-full">
          {/* TOP RIGHT */}
          <div className="absolute top-0 right-0 overflow-hidden">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/banner-circle.webp"
              className="w-[15vh] xl:w-[27vh] lg:w-[27vh] h-auto banner-shape hidden xl:block lg:block"
              alt="Simplify, Analyze, and Optimize Your Trading Experience"
            />
          </div>

          {/* BOTTOM LEFT */}
          <div className="absolute bottom-0 left-0 overflow-hidden">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/banner-circle.webp"
              className="w-[15vh] xl:w-[27vh] lg:w-[27vh] h-auto banner-shape-bottom hidden xl:block lg:block"
              alt="Simplify, Analyze, and Optimize Your Trading Experience"
            />
          </div>
        </div>
      </motion.div>

      {/* PRICING PLANS */}
      <motion.div
        variants={animation}
        className="md:px-2 lg:px-0 py-5 xl:py-14 lg:py-14"
      >
        <div className="container">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
            {pricingPlans.map((data) => (
              <div className="col-span-1" key={data.id}>
                <PricingBox
                  name={data.name}
                  description={data.description}
                  features={data.features}
                  monthlyPrice={data.monthlyPrice}
                  originalMonthlyPrice={data.originalMonthlyPrice}
                  monthlyDiscount={data.monthlyDiscount}
                  annualPrice={data.annualPrice}
                  originalAnnualPrice={data.originalAnnualPrice}
                  annualDiscount={data.annualDiscount}
                  off={"Get 30% off on your first month!"}
                  isMonthly={activeTab === "Monthly" ? true : false}
                  onClickFN={() => {
                    registerFormikHandling.resetForm();
                    setSelectedPlan({
                      name: data.name,
                      description: data.description,
                      features: data.features,
                      monthlyPrice: data.monthlyPrice,
                      originalMonthlyPrice: data.originalMonthlyPrice,
                      monthlyDiscount: data.monthlyDiscount,
                      annualPrice: data.annualPrice,
                      originalAnnualPrice: data.originalAnnualPrice,
                      annualDiscount: data.annualDiscount,
                      isMonthly: activeTab === "Monthly" ? true : false,
                      id: activeTab === "Monthly" ? data?.id : data?.yearId,
                    });
                    setPricingDialog(!pricingDialog);
                  }}
                />
              </div>
            ))}
          </div>

          {/* BUSINESS */}
          <div className="bg-white border border-l-8 border-greyBorder px-5 xl:px-10 lg:px-10 py-5 xl:py-8 lg:py-8 mt-5 shadow-md rounded-xl">
            <div className="block xl:flex lg:flex items-center justify-between">
              <div className="w-full xl:w-4/5 lg:w-4/5">
                <h5 className="text-base font-proxima-semibold text-black">
                  Business Plan
                </h5>
                <h5 className="text-2xl font-helvetica-bold tracking-tight text-black mt-2">
                  Need more features according to your requirements?
                </h5>
                <p className="text-lg font-proxima-regular text-grey tracking-tight">
                  Customize a pricing plan that scales to your requirements
                </p>
              </div>
              <div className="w-full xl:w-1/5 lg:w-1/5 flex items-center justify-start xl:justify-end lg:justify-end mt-5 xl:mt-0 lg:mt-0">
                <Button
                  className="w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-logo text-base font-proxima-semibold text-white normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                  onClick={() => setContactDialog(!contactDialog)}
                >
                  Contact Sales
                </Button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <FrequentlyAskedQuestions />

      {/* TESTIMONIALS */}
      <Testimonials />

      {/* CONTACT US POPUP */}
      <Dialog
        open={contactDialog}
        handler={() => setContactDialog(!contactDialog)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
        dismiss={{
          enabled: false,
        }}
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between border-b border-greyBorder px-5 py-3">
            <div>
              <h5 className="text-lg font-proxima-bold text-black">
                Contact Sales
              </h5>
              <p className="text-sm font-proxima-regular text-grey">
                Fill in these details to contact our sales team
              </p>
            </div>
            <Button
              className="shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-10 rounded-full transition-all duration-300 ease-in-out p-2"
              onClick={() => setContactDialog(!contactDialog)}
            >
              <IoClose className="w-4 h-4 text-black" />
            </Button>
          </div>
          <div className="p-5">
            <form onSubmit={formikHandling.handleSubmit}>
              <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formikHandling.values.firstName}
                        onChange={formikHandling.handleChange}
                        className="outline-none w-full block bg-white border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold text-black placeholder:font-proxima-regular"
                      />
                      {formikHandling.errors.firstName &&
                        formikHandling.touched.firstName && (
                          <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                            {formikHandling.errors.firstName}
                          </small>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formikHandling.values.lastName}
                        onChange={formikHandling.handleChange}
                        className="outline-none w-full block bg-white border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold text-black placeholder:font-proxima-regular"
                      />
                      {formikHandling.errors.lastName &&
                        formikHandling.touched.lastName && (
                          <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                            {formikHandling.errors.lastName}
                          </small>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Email address <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email address"
                        value={formikHandling.values.email}
                        onChange={formikHandling.handleChange}
                        className="outline-none w-full block bg-white border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold text-black placeholder:font-proxima-regular"
                      />
                      {formikHandling.errors.email &&
                        formikHandling.touched.email && (
                          <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                            {formikHandling.errors.email}
                          </small>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Phone <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <PhoneInput
                        placeholder="Phone"
                        countries={["IN"]}
                        defaultCountry="IN"
                        countryCallingCodeEditable={false}
                        className="border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold text-black"
                        value={formikHandling.values.phone}
                        onChange={(e) => {
                          formikHandling.setFieldValue("phone", e);
                        }}
                      />
                      {formikHandling.errors.phone &&
                        formikHandling.touched.phone && (
                          <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                            {formikHandling.errors.phone}
                          </small>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Message <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows={2}
                        name="message"
                        placeholder="Type your message"
                        className="w-full block border border-greyBorder rounded-lg px-5 py-2 text-sm font-proxima-semibold placeholder:font-proxima-regular text-black"
                        value={formikHandling.values.message}
                        onChange={formikHandling.handleChange}
                      />
                      {formikHandling.errors.message &&
                        formikHandling.touched.message && (
                          <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                            {formikHandling.errors.message}
                          </small>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <Button
                disabled={formLoading}
                type={formLoading ? "button" : "submit"}
                className="mt-5 shadow-none hover:shadow-none bg-logo rounded-md text-sm font-proxima-semibold text-white px-5 py-2 normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out"
              >
                {formLoading ? "Please wait.." : "Submit details"}
              </Button>
            </form>
          </div>
        </DialogBody>
      </Dialog>

      {/* PRICING POPUP */}
      <Dialog
        open={pricingDialog}
        handler={() => setPricingDialog(!pricingDialog)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
        dismiss={{
          enabled: false,
        }}
      >
        <DialogBody className="p-5 relative max-h-[75vh] xl:max-h-max lg:max-h-max overflow-auto hide-scrollbar">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 items-center">
            <div className="col-span-1 p-0 xl:p-5 lg:p-5">
              {/* HEADING */}
              <div className="p-0">
                <h5 className="text-2xl font-proxima-extrabold tracking-tight text-black">
                  Get started with your account
                </h5>
                <p className="text-base font-proxima-regular text-grey leading-snug mt-1">
                  Create your account to log your trades and gain a
                  comprehensive view of your trades, strategies and portfolio.
                </p>
              </div>

              {/* FORM */}
              <form
                onSubmit={registerFormikHandling.handleSubmit}
                className="mt-5"
              >
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        First name <span className="text-red-400">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder="Type your first name"
                          value={registerFormikHandling.values.firstName}
                          onChange={registerFormikHandling.handleChange}
                          className="w-full block bg-white px-4 py-3 rounded-md text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-logo placeholder:font-proxima-regular"
                        />
                        {registerFormikHandling.errors.firstName &&
                          registerFormikHandling.touched.firstName && (
                            <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                              {registerFormikHandling.errors.firstName}
                            </small>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Last name <span className="text-red-400">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={registerFormikHandling.values.lastName}
                          onChange={registerFormikHandling.handleChange}
                          placeholder="Type your last name"
                          className="w-full block bg-white px-4 py-3 rounded-md text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-logo placeholder:font-proxima-regular"
                        />
                        {registerFormikHandling.errors.lastName &&
                          registerFormikHandling.touched.lastName && (
                            <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                              {registerFormikHandling.errors.lastName}
                            </small>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Username <span className="text-red-400">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="userName"
                        id="userName"
                        placeholder="Type your username"
                        className="w-full block bg-white px-4 py-3 rounded-md text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-logo placeholder:font-proxima-regular"
                        value={registerFormikHandling.values.userName}
                        onChange={registerFormikHandling.handleChange}
                      />
                      {registerFormikHandling.errors.userName &&
                        registerFormikHandling.touched.userName && (
                          <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                            {registerFormikHandling.errors.userName}
                          </small>
                        )}
                    </div>
                  </div>
                </div> */}
                <div className="mt-5">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Email address <span className="text-red-400">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={registerFormikHandling.values.email}
                        onChange={registerFormikHandling.handleChange}
                        placeholder="Type your email address"
                        className="w-full block bg-white px-4 py-3 rounded-md text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-logo placeholder:font-proxima-regular"
                      />
                      {registerFormikHandling.errors.email &&
                        registerFormikHandling.touched.email && (
                          <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                            {registerFormikHandling.errors.email}
                          </small>
                        )}
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Password <span className="text-red-400">*</span>
                    </label>
                    <div className="mt-1 relative">
                      <input
                        type={passwordToggle ? "text" : "password"}
                        name="password"
                        id="password"
                        value={registerFormikHandling.values.password}
                        onChange={registerFormikHandling.handleChange}
                        placeholder="Type your password"
                        className="w-full block bg-white px-4 py-3 rounded-md text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-logo placeholder:font-proxima-regular"
                      />
                      {registerFormikHandling.errors.password &&
                        registerFormikHandling.touched.password && (
                          <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                            {registerFormikHandling.errors.password}
                          </small>
                        )}
                      <div className="absolute top-2 right-2">
                        <Button
                          className="shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-10 rounded-full p-2 transition-all duration-300 ease-in-out"
                          onClick={() => setPasswordToggle(!passwordToggle)}
                        >
                          {passwordToggle ? (
                            <IoMdEyeOff className="w-5 h-5 text-grey" />
                          ) : (
                            <IoMdEye className="w-5 h-5 text-grey" />
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  disabled={formLoading}
                  type="submit"
                  className="mt-5 shadow-none hover:shadow-none normal-case bg-logo hover:bg-opacity-70 transition-all duration-300 ease-in-out rounded-md py-3 text-sm font-proxima-semibold text-white"
                >
                  {formLoading
                    ? "Redirecting you to payment gateway.."
                    : "Proceed with payment"}
                </Button>
              </form>

              <p className="mt-3 text-sm font-proxima-regular text-grey italic">
                <span className="font-proxima-semibold text-black">Note:</span>{" "}
                This screen will automatically refresh once you complete your
                payment and close that popup/tab.
              </p>
            </div>
            <div className="col-span-1">
              <div className="w-full h-full newsletter-gradient rounded-lg p-5 flex items-center">
                <div className="bg-white px-8 py-3 rounded-md">
                  <div className="py-5 border-b border-greyBorder">
                    {/* PLAN PRICE AND NAME */}
                    <div className="p-0">
                      <h5 className="text-base font-proxima-semibold text-black">
                        {selectedPlan?.name}
                      </h5>

                      {/* PRICE */}
                      <div className="p-0">
                        {selectedPlan?.isMonthly ? (
                          selectedPlan?.name === "Personal Plan" ? (
                            <h2 className="mt-3 text-4xl font-proxima-bold tracking-tight text-black leading-none">
                              ₹
                              {parseFloat(
                                selectedPlan?.monthlyPrice
                              ).toLocaleString("en-IN")}
                              {/* SHOW CUTOFF PRICES */}
                              <span className="text-xl font-proxima-semibold text-red-500 line-through ml-2">
                                ₹
                                {parseFloat(
                                  selectedPlan?.originalMonthlyPrice
                                ).toLocaleString("en-IN")}
                              </span>
                            </h2>
                          ) : (
                            <h2 className="mt-3 text-4xl font-proxima-bold tracking-tight text-black leading-none">
                              ₹
                              {parseFloat(
                                selectedPlan?.monthlyPrice
                              ).toLocaleString("en-IN")}
                            </h2>
                          )
                        ) : (
                          <h2 className="mt-3 text-4xl font-proxima-bold tracking-tight text-black leading-none">
                            ₹
                            {parseFloat(
                              selectedPlan?.annualPrice
                            ).toLocaleString("en-IN")}
                            {/* SHOW CUTOFF PRICES */}
                            <span className="text-2xl font-proxima-semibold text-red-500 line-through ml-2">
                              ₹
                              {parseFloat(
                                selectedPlan?.originalAnnualPrice
                              ).toLocaleString("en-IN")}
                            </span>
                          </h2>
                        )}
                      </div>

                      {selectedPlan?.isMonthly ? (
                        <p className="text-sm font-proxima-regular text-grey mt-2">
                          * billed monthly
                        </p>
                      ) : (
                        <p className="text-sm font-proxima-regular text-grey mt-2">
                          * billed annually
                        </p>
                      )}
                    </div>

                    {/* PLAN DESCRIPTION */}
                    <div className="mt-3">
                      <p className="text-base font-proxima-regular text-grey">
                        {selectedPlan?.description}
                      </p>
                    </div>
                  </div>

                  {/* FEATURES */}
                  <div className="py-5">
                    <h4 className="text-lg font-proxima-bold text-black">
                      This plan includes:
                    </h4>

                    {selectedPlan?.features?.map((item) => (
                      <div
                        className="flex items-center gap-3 mt-2 first:mt-0"
                        key={item.id}
                      >
                        <FaCircleCheck className="w-5 h-5 text-logo" />
                        <p className="text-base font-proxima-regular text-grey">
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute top-2 right-2">
            <Button
              className="shadow-none hover:shadow-none p-1 bg-white hover:bg-black text-grey hover:text-white rounded-full transition-all duration-300 ease-in-out"
              onClick={() => setPricingDialog(!pricingDialog)}
            >
              <IoClose className="w-5 h-5" />
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </motion.section>
  );
};

export default Pricing;
