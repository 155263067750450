import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ANIMATION */
import { animation } from "data/animation";

const Footer = () => {
  return (
    <motion.footer
      variants={animation}
      className="bg-greyBg p-5 xl:p-0 lg:p-0 shadow border border-greyBorder"
    >
      <div className="container">
        {/* CTA */}
        <div className="block xl:flex lg:flex items-center justify-between py-5 xl:py-10 lg:py-10 border-b border-greyBorder">
          <div className="w-full xl:w-1/2 lg:w-1/2">
            <h5 className="text-2xl xl:text-4xl lg:text-4xl font-helvetica-bold tracking-tight text-black">
              Journal your trades with TradeTalez
            </h5>
            <p className="text-lg xl:text-xl lg:text-xl font-proxima-regular text-grey mt-2 xl:mt-0 lg:mt-0 tracking-tight">
              Track, Analyze, and Journal your trades easily and effectively.
              Start your journey to consistent success today with TradeTalez.
            </p>
          </div>
          <div className="w-full xl:w-2/5 lg:w-2/5 mt-5 xl:mt-0 lg:mt-0 block xl:flex lg:flex items-center justify-end gap-3">
            <Link to="/pricing">
              <Button className="w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-logo border border-logo rounded-lg text-white font-proxima-semibold text-base hover:bg-opacity-80 transition-all duration-300 ease-in-out normal-case">
                Start Journaling
              </Button>
            </Link>
            <Link to="/resource-center">
              <Button className="w-full xl:w-fit lg:w-fit mt-3 xl:mt-0 lg:mt-0 shadow-none hover:shadow-none bg-transparent hover:bg-logo border border-logo text-base font-proxima-semibold text-logo normal-case hover:bg-opacity-10 transition-all duration-300 ease-in-out">
                Watch demo
              </Button>
            </Link>
          </div>
        </div>

        {/* FOOTER */}
        <div className="block xl:flex lg:flex items-center justify-between py-5 xl:py-10 lg:py-10 border-b border-greyBorder">
          <div>
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/logo.webp"
              className="w-footerLogo h-auto mx-auto xl:mx-0 lg:mx-0"
              alt="Trade Talez"
            />
            <p className="text-base font-proxima-semibold text-black mt-3 leading-none text-center xl:text-left lg:text-left">
              Record, Reflect and Rise
            </p>
          </div>
          <div className="flex items-center gap-5 gap-y-0 xl:gap-10 lg:gap-10 mt-5 xl:mt-0 lg:mt-0 flex-wrap justify-center xl:justify-normal lg:justify-normal">
            <Link
              to="/"
              className="block text-base xl:text-lg lg:text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out cursor-pointer"
            >
              About Us
            </Link>
            <Link
              to="/our-features"
              className="block text-base xl:text-lg lg:text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out cursor-pointer"
            >
              Our Features
            </Link>
            <Link
              to="/pricing"
              className="block text-base xl:text-lg lg:text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out cursor-pointer"
            >
              Pricing
            </Link>
            <Link
              to="/broker-support"
              className="block text-base xl:text-lg lg:text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out cursor-pointer"
            >
              Broker Support
            </Link>
            <Link
              to="/resource-center"
              className="block text-base xl:text-lg lg:text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out cursor-pointer"
            >
              Resource Center
            </Link>
            <Link
              to="/contact-us"
              className="block text-base xl:text-lg lg:text-lg font-proxima-semibold text-black tracking-tight hover:text-logo transition-all duration-300 ease-in-out cursor-pointer"
            >
              Contact Us
            </Link>
          </div>
        </div>

        {/* COPYRIGHT FOOTER */}
        <div className="block xl:flex lg:flex items-center justify-between py-5">
          <h5 className="text-base font-proxima-regular text-black text-center xl:text-left lg:text-left">
            Copyright © 2024 TradeTalez. A product by{" "}
            <span className="text-black font-proxima-semibold">
              Tactical Trading Technologies LLP
            </span>
            . Powered by{" "}
            <a
              href="https://www.roundtechsquare.com"
              target="_blank"
              rel="noreferrer"
              className="text-[#086AD8] font-proxima-semibold"
            >
              RoundTechSquare
            </a>
          </h5>
          <div className="flex items-center justify-center xl:justify-normal lg:justify-normal gap-10 mt-5 xl:mt-0 lg:mt-0">
            <Link
              to="/refund-policy"
              className="block text-base font-proxima-regular text-black hover:text-logo transition-all duration-300 ease-in-out"
            >
              Refund Policy
            </Link>
            <Link
              to="/terms-conditions"
              className="block text-base font-proxima-regular text-black hover:text-logo transition-all duration-300 ease-in-out"
            >
              Terms & Conditions
            </Link>
            <Link
              to="/privacy-policy"
              className="block text-base font-proxima-regular text-black hover:text-logo transition-all duration-300 ease-in-out"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
