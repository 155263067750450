import { MdDashboard } from "react-icons/md";
import { IoJournal } from "react-icons/io5";
import { FaFileImport } from "react-icons/fa6";
import { BiSolidReport } from "react-icons/bi";
import { FaListAlt } from "react-icons/fa";
import { IoSettings, IoCloseCircle } from "react-icons/io5";

export const categories = [
  {
    id: 1,
    icon: FaFileImport,
    title: "Import Trades",
    description:
      "Import your trades efficiently to automate your digital trade journaling. This can be done through three methods.",
    items: [
      {
        id: 0,
        title: "File Upload",
        subItems: [
          {
            id: 0,
            title: "Access:",
            desc: `Go to 'Import Trades' and select 'File Upload'.`,
          },
          {
            id: 1,
            title: "Action:",
            desc: `Choose your broker from the provided list and upload the CSV file of your trade
              book. Specific instructions for each broker are listed under their name to guide you
              through the upload process.`,
          },
        ],
      },
      {
        id: 1,
        title: "Broker Sync",
        subItems: [
          {
            id: 0,
            title: "Access:",
            desc: `Go to 'Import Trades' and choose 'Broker Sync'.`,
          },
          {
            id: 1,
            title: "Action:",
            desc: `Click 'Add a New Broker', select your broker, and press 'Connect'. A pop-up will
              appear with detailed steps to complete the synchronization process, allowing for
              automatic trade data retrieval.`,
          },
        ],
      },
      {
        id: 2,
        title: "Manual Square Off in Daily Journal",
        subItems: [
          {
            id: 0,
            title: "When to Use:",
            desc: `Opt for manual square-off if there are syncing issues or missing data due to broker errors.`,
          },
          {
            id: 1,
            title: "Scenarios for Manual Square Off:",
            desc: ``,
            nestedItems: [
              {
                id: 0,
                title: "Partially Cancelled Orders:",
                desc: `If your broker fails to send data for partially canceled orders, you must manually Square off the open orders to ensure your records are accurate.`,
              },
              {
                id: 1,
                title: "Forgot to Sync:",
                desc: `If you conduct trades but forget to sync them on the same day, you'll need to manually square off the open position if any.`,
              },
              {
                id: 2,
                title: "Broker Errors:",
                desc: `Any other discrepancies caused by the broker should be corrected through manual square-off.`,
              },
            ],
          },
          {
            id: 3,
            title: "Access:",
            desc: `Click on 'Square Off' on the open position card in ‘Daily Journal’.`,
          },
          {
            id: 4,
            title: "Action:",
            desc: `Manually input the necessary trade details into the fields to maintain accurate records.`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    icon: MdDashboard,
    title: "Dashboard",
    description:
      "The dashboard is designed to give you a quick overview of your trading performance along with time range selection.",
    items: [
      {
        title: "Using the Date Filter:",
        desc: `Click on the 'Date Filter' to select the range you want to analyse. The dashboard will update to reflect statistics for this period.`,
        subItems: [],
      },
      {
        title: "Understanding the Stats:",
        desc: "",
        subItems: [
          {
            title: "Net P&L:",
            desc: "Displays the total profit and loss for the selected date range.",
          },
          {
            title: "Trade Win (%):",
            desc: "Shows the percentage of trades that were profitable.",
          },
          {
            title: "Profit Factor:",
            desc: "Indicates the ratio of total gains to total losses. A higher number suggests more profitable trades.",
          },
        ],
      },
      {
        title: "Calendar View:",
        desc: "Days with trades are marked in red (loss) and green (profit). Navigate through months to review specific trades.",
        subItems: [],
      },
      {
        title: "Account Selection:",
        desc: `The 'All Accounts' dropdown allows you to filter the statistics for specific accounts or view them collectively.`,
        subItems: [],
      },
    ],
  },
  {
    id: 3,
    icon: IoJournal,
    title: "Daily Journal",
    description:
      "This section helps you track and analyse your daily trading activities as well as organize your trading data effortlessly.",
    items: [
      {
        title: "Monthly Navigation:",
        desc: "Select a month to display all the trades conducted within that period.",
        subItems: [],
      },
      {
        title: "Day Selection:",
        desc: "Click on a specific day to view detailed trades.",
        subItems: [],
      },
      {
        title: "Trade Details: Commissions and Notes:",
        desc: "Here, you can add any commissions associated with the trades and day notes for future reference.",
        subItems: [],
      },
      {
        title: "Filters:",
        desc: "You have options to filter the journal by mistakes, product types, or trade sides.",
        subItems: [],
      },
      {
        title: "Trading Cards:",
        desc: "Each trade is represented by a card detailing the stock or derivative name, type, quantity, net P&L, and ROI.",
        subItems: [
          {
            title: "Setup/Mistake Tags:",
            desc: "From the trading card, you can add setups or mistakes previously defined in 'Setup Management'. This helps in analysing trading strategies and errors.",
          },
          {
            title: "Add notes:",
            desc: "Here you can add notes for your trade.",
          },
          {
            title: "In-depth Trade details:",
            desc: "Access this feature by pressing the arrow button in trade card.",
            subItems: [
              {
                title:
                  "Add Target price and Stop Loss to get your expected and realized RR.",
                desc: "",
              },
              {
                title: "Upload screenshot of your trade.",
                desc: "",
              },
            ],
          },
          /* {
            title: "PS:",
            desc: "Professional subscribers can also access the trade replay feature to review trades in a simulated format.",
          }, */
        ],
      },
    ],
  },
  {
    id: 4,
    icon: FaListAlt,
    title: "Trade Log",
    description:
      "This section provides an overview of your trading metrics and individual trade reviews on our platform.",
    items: [
      {
        title: "Displayed Metrics:",
        desc: "Includes win percentage, profit factor, and average win/loss per trade.",
        subItems: [],
      },
      {
        title: "Filtering Options:",
        desc: "Filter to see all trades, only wins, or only losses for detailed analysis.",
        subItems: [],
      },
      {
        title: "Trade Details:",
        desc: "'View Details' provides a deeper look into each trade's specifics.",
        subItems: [],
      },
    ],
  },
  {
    id: 5,
    icon: BiSolidReport,
    title: "Reports",
    description:
      "Detailed reports provide insights into various aspects of your trading activities based on your trade’s entry data.",
    items: [
      {
        title: "Overview:",
        desc: "Displays key statistics like total P&L, number of trades, and averages for the selected date range.",
        subItems: [],
      },
      {
        title: "Performance Analysis:",
        desc: "Analyse performance by days, weeks, trade timing, duration, setups, and mistakes.",
        subItems: [],
      },
      {
        title: "Risk Reward Analysis:",
        desc: "This section helps you understand the effectiveness of your risk management by comparing expected and realized risk/reward ratios.",
        subItems: [],
      },
    ],
  },
  {
    id: 6,
    icon: IoSettings,
    title: "Setup Management",
    description:
      "This is where you add and manage your trading setups and mistakes along with different colors for easy identification.",
    items: [
      {
        title: "Functionality:",
        desc: `This is where you add and manage your trading setups and mistakes. You can also
      provide them coloured tags for easy identification in the Daily Journal.`,
        subItems: [],
      },
    ],
  },
  {
    id: 7,
    icon: IoCloseCircle,
    title: "Clear Trades",
    description:
      "This function allows you to remove trades from a specific account within a designated time frame.",
    items: [
      {
        id: 0,
        title: "Access:",
        desc: `Go to your 'Profile' and select 'Accounts' tab.`,
        subItems: [],
      },
      {
        id: 1,
        title: "Action:",
        desc: `Click on the three dots next to the account you wish to clear trades from. Select 'Clear Trade', choose the date range, and follow the on-screen instructions to complete the process.`,
        subItems: [],
      },
    ],
  },
];
