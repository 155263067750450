const pricingPlans = [
  {
    id: "6659cb9d46229b53415f9f1f",
    yearId: "6659cbe1a7eb921bd4f508fb",
    name: "Basic Plan",
    monthlyPrice: "699",
    annualPrice: "6289",
    originalAnnualPrice: "8388",
    annualDiscount: "25",
    description:
      "Kickstart your trading journey with us for less than the cost of a single meal.",
    features: [
      {
        id: 1,
        title: "1 Account",
      },
      {
        id: 2,
        title: "Trade Reports",
      },
      {
        id: 3,
        title: "Trade Logs",
      },
      {
        id: 4,
        title: "CSV Upload",
      },
      {
        id: 5,
        title: "Can add upto 2 Setups, 2 Mistakes",
      },
      {
        id: 6,
        title: "Basic Support",
      },
    ],
  },
  {
    id: "6659cc5da7eb921bd4f508fc",
    yearId: "6659cc8da7eb921bd4f508fd",
    name: "Personal Plan",
    monthlyPrice: "699",
    monthlyDiscount: "5",
    originalMonthlyPrice: "999",
    annualPrice: "8399",
    originalAnnualPrice: "11988",
    annualDiscount: "30",
    description:
      "It's time to invest in your future with tools designed for those serious about turning market savvy into a full-time, lucrative career.",
    features: [
      {
        id: 1,
        title: "5 Accounts",
      },
      {
        id: 2,
        title: "Trade Reports",
      },
      {
        id: 3,
        title: "Trade Logs",
      },
      {
        id: 4,
        title: "CSV Upload",
      },
      {
        id: 5,
        title: "Can add upto 5 Setups, 5 Mistakes",
      },
      {
        id: 6,
        title: "Priority Support",
      },
      {
        id: 7,
        title: "Personalized Insights (TT insights)",
      },
      /* {
        id: 8,
        title: "Trade Replay",
      }, */
    ],
  },
  {
    id: "6659ccd4a7eb921bd4f508fe",
    yearId: "6659cd04a7eb921bd4f508ff",
    name: "Professional Plan",
    monthlyPrice: "909",
    annualPrice: "11689",
    originalMonthlyPrice: "1299",
    originalAnnualPrice: "15588",
    annualDiscount: "25",
    description:
      "You know you need it, you know you want it—and we're serving it up on a silver platter, at a price you won't even blink at.",
    features: [
      {
        id: 1,
        title: "10 Accounts",
      },
      {
        id: 2,
        title: "Trade Reports",
      },
      {
        id: 3,
        title: "Trade Logs",
      },
      {
        id: 4,
        title: "CSV Upload",
      },
      {
        id: 5,
        title: "Can add upto unlimited setups and mistakes",
      },
      {
        id: 6,
        title: "Priority Support",
      },
      {
        id: 7,
        title: "Personalized Insights (TT insights)",
      },
      /* {
        id: 8,
        title: "Trade Replay",
      }, */
    ],
  },
];

export default pricingPlans;
