import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* PACKAGES */
import { motion } from "framer-motion";
import { toast } from "react-toastify";

/* ICONS */
import { FaCircleCheck, FaArrowRightLong } from "react-icons/fa6";

/* ANIMATIONS */
import { animation, containerAnimation } from "data/animation";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* APIS */
import { waitingListAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .strict("First name is required")
    .min(1, "First name is required")
    .required("First name is required"),
  lastName: yup
    .string()
    .trim()
    .strict("Last name is required")
    .min(1, "Last name is required")
    .required("Last name is required"),
  email: yup
    .string()
    .email()
    .trim()
    .strict(true, "Email Address is required")
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* FEATURES DATA */
const features = [
  {
    id: 1,
    title: "Seamless Broker Integration",
    description:
      "Easily link up with top brokers such as Zerodha, Fyers, Dhan, and Upstox. Our user-friendly platform ensures secure trading. Simplify your trading journey with us.",
  },
  {
    id: 2,
    title: "Automated Data Synchronization",
    description:
      "Connect your account for streamlined trading data management. Our platform automates trade-to-portfolio analytics, removing manual input hassle. Focus on strategy, not data entry.",
  },
  {
    id: 3,
    title: "Trading Metrics Analysis",
    description:
      "Explore metrics to optimize your trading strategy which includes Profit Factor, Win Rate, Daily Rate, Average Win/Loss Ratio, Trade Expectancy, Account Aggregator and many more.",
  },
  {
    id: 4,
    title: "Digital Trading Journal",
    description:
      "Access your organized trading data effortlessly. Customize with notes and strategies, sans doodles. It's like having a coach who embraces your style. Analyzing moves made enjoyable!",
  },
  {
    id: 5,
    title: "In-Depth Reports",
    description:
      "Discover your trading ally: TradeTalez reports. Celebrate victories, identify areas to enhance. No more spreadsheet overwhelm. Simplified performance insights!",
  },
  {
    id: 6,
    title: "TT insights: Your Trading Treasure Map",
    description:
      "Picture TT insights as your reliable treasure map navigator. As you sail the trading seas with detailed reports, our insights pinpoint treasure locations, tailored to refine your edge.",
  },
];

const WaitList = () => {
  const [formLoading, setFormLoading] = useState(false);

  /* POINTS */
  const FeatureTile = (props) => {
    return (
      <div className="mt-5 first:mt-0">
        <div className="flex items-center gap-3">
          <FaCircleCheck className="w-5 h-5 text-logo flex-shrink-0" />
          <h5 className="text-xl font-proxima-bold text-black tracking-tight">
            {props.title}
          </h5>
        </div>
        <p className="text-base font-proxima-regular text-grey mt-3 leading-relaxed">
          {props.description}
        </p>
      </div>
    );
  };

  /* WAITLIST FORM HANDLER */
  const waitlistHandler = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };
      setFormLoading(true);
      waitingListAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Grateful for your interest! You're officially on our waitlist. Stay tuned for updates soon!"
            );
          } else {
            toast.error(
              "An error was encountered. Please try again to fill the details."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(
            "An error was encountered. Please try again after sometime."
          );
        })
        .finally((res) => {
          waitlistHandler.handleReset();
          setFormLoading(false);
        });
    },
  });

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div
        variants={animation}
        className="h-auto xl:h-[90vh] lg:h-[90vh]"
      >
        <div className="container h-full">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-10 xl:gap-0 lg:gap-0 h-full md:px-4 lg:px-0 md:my-2 lg:my-0">
            {/* FEATURES CONTENT */}
            <div className="col-span-1 border-r-0 xl:border-r lg:border-r border-greyBorder pr-0 xl:pr-20 lg:pr-20 flex items-center order-2 xl:order-1 lg:order-1">
              <div>
                <h1 className="text-3xl xl:text-4xl lg:text-4xl font-helvetica-bold tracking-tight text-black leading-tight">
                  Your Trading Journal For Better Trading Decisions
                </h1>
                <div className="mt-5 xl:mt-8 lg:mt-8">
                  {features.map((data) => (
                    <React.Fragment key={data.id}>
                      <FeatureTile
                        title={data.title}
                        description={data.description}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>

            {/* FORM */}
            <div className="col-span-1 flex items-center order-1 xl:order-2 lg:order-2">
              <div className="w-full xl:w-3/5 lg:w-3/5 mx-auto">
                <div className="pb-5 border-b border-greyBorder">
                  <div className="bg-green-500 bg-opacity-10 rounded-full border border-green-500 px-5 py-2">
                    <p className="text-base font-proxima-semibold text-green-500 text-center">
                      50 <span className="uppercase">RANDOM</span> waitlisted
                      individuals will receive complimentary personal tier
                      access for 1 month.
                    </p>
                  </div>
                  <h2 className="mt-5 text-3xl xl:text-4xl lg:text-4xl font-helvetica-bold tracking-tight text-black leading-tight text-left xl:text-center lg:text-center">
                    Join our journey and get early access
                  </h2>
                  <h5 className="text-lg font-proxima-regular text-grey text-left xl:text-center lg:text-center tracking-tight mt-5">
                    Join our extensive waitlist today to journal your trades and
                    get notified when we launch.
                  </h5>
                </div>
                <form
                  className="py-5 border-b border-greyBorder"
                  onSubmit={waitlistHandler.handleSubmit}
                >
                  <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                    <div className="col-span-1">
                      <div className="form-group">
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder="First name"
                          value={waitlistHandler.values.firstName}
                          onChange={waitlistHandler.handleChange}
                          className="w-full block bg-greyBg bg-opacity-50 px-4 py-3 rounded-md text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-logo placeholder:font-proxima-regular"
                        />
                        {waitlistHandler.errors.firstName &&
                          waitlistHandler.touched.firstName && (
                            <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                              {waitlistHandler.errors.firstName}
                            </small>
                          )}
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder="Last name"
                          value={waitlistHandler.values.lastName}
                          onChange={waitlistHandler.handleChange}
                          className="w-full block bg-greyBg bg-opacity-50 px-4 py-3 rounded-md text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-logo placeholder:font-proxima-regular"
                        />
                        {waitlistHandler.errors.lastName &&
                          waitlistHandler.touched.lastName && (
                            <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                              {waitlistHandler.errors.lastName}
                            </small>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-5">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email address"
                      value={waitlistHandler.values.email}
                      onChange={waitlistHandler.handleChange}
                      className="w-full block bg-greyBg bg-opacity-50 px-4 py-3 rounded-md text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-logo placeholder:font-proxima-regular"
                    />
                    {waitlistHandler.errors.email &&
                      waitlistHandler.touched.email && (
                        <small className="block text-sm font-proxima-semibold text-red-500 mt-1">
                          {waitlistHandler.errors.email}
                        </small>
                      )}
                  </div>
                  <Button
                    disabled={formLoading}
                    type={formLoading ? "button" : "submit"}
                    className="mt-5 w-full shadow-none hover:shadow-none bg-logo rounded-md flex items-center gap-2 justify-center text-sm font-proxima-semibold normal-case"
                  >
                    {formLoading ? "Please wait.." : "Submit details"}
                    <FaArrowRightLong className="w-4 h-4 text-white" />
                  </Button>
                </form>
                <div className="pt-5">
                  <p className="w-full xl:w-3/5 lg:w-3/5 mx-auto text-sm font-proxima-semibold text-grey text-left xl:text-center lg:text-center">
                    By clicking "Submit details" you agree to our{" "}
                    <Link to="/privacy-policy" className="text-logo">
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link to="/terms-of-use" className="text-logo">
                      Terms of Use
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default WaitList;
