/* PACKAGES */
import { toast } from "react-toastify";

/* ICONS */
import { FaCircleCheck, FaRocket } from "react-icons/fa6";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

const PricingBox = (props) => {
  return (
    <div
      className={`w-full h-full bg-white ${
        props.name === "Personal Plan"
          ? "border-2 border-logo border-b-8 border-b-logo"
          : "border border-greyBorder border-b-8 border-b-greyBorder"
      } rounded-xl shadow-md relative`}
    >
      {props.isMonthly &&
        (props.name === "Basic Plan" ||
          props.name === "Professional Plan" ||
          props.name === "Personal Plan") && (
          <div className="w-full bg-black py-1.5 rounded-t-xl border border-black">
            <p className="text-sm font-proxima-semibold text-white text-center">
              ✨ Special Launch Offer
            </p>
          </div>
        )}

      {/* PLAN DETAILS */}
      <div className="p-5 xl:p-8 lg:p-8 border-b border-greyBorder">
        {!props.isMonthly && (
          <div className="flex items-center gap-2">
            <div className="badge-gradient rounded-full px-3 py-1">
              <p className="text-sm font-proxima-semibold text-white">
                Save {props.annualDiscount}%
              </p>
            </div>

            {props.name === "Personal Plan" && (
              <div className="w-fit badge-gradient rounded-full px-2 py-1 text-white font-proxima-semibold flex items-center gap-2 text-sm">
                <FaRocket className="w-3 h-3 text-white" />
                Recommended
              </div>
            )}
          </div>
        )}

        {props.name === "Basic Plan" && props.isMonthly && (
          <div className="p-0">
            <div className="w-fit badge-gradient rounded-full px-3 py-1 text-white font-proxima-semibold flex items-center gap-2 text-sm">
              15 days free trial
            </div>
          </div>
        )}

        {props.name === "Personal Plan" && props.isMonthly && (
          <div className="flex items-center gap-2">
            <div className="w-fit badge-gradient rounded-full px-3 py-1 text-white font-proxima-semibold flex items-center gap-2 text-sm">
              {props.off}
            </div>

            {props.name === "Personal Plan" && (
              <div className="w-fit badge-gradient rounded-full px-3 py-1 text-white font-proxima-semibold flex items-center gap-2 text-sm">
                <FaRocket className="w-3 h-3 text-white" />
                Recommended
              </div>
            )}
          </div>
        )}

        {props.name === "Professional Plan" && props.isMonthly && (
          <div className="p-0">
            <div className="w-fit badge-gradient rounded-full px-3 py-1 text-white font-proxima-semibold flex items-center gap-2 text-sm">
              {props.off}
            </div>
          </div>
        )}

        {/* PLAN PRICE AND NAME */}
        <div className="mt-4">
          <h5 className="text-base font-proxima-semibold text-black">
            {props.name}
          </h5>

          {/* PRICE */}
          <div className="p-0">
            {props.isMonthly ? (
              props.name === "Personal Plan" ||
              props.name === "Professional Plan" ? (
                <h2 className="mt-5 text-5xl font-proxima-bold tracking-tight text-black leading-none">
                  ₹{parseFloat(props.monthlyPrice).toLocaleString("en-IN")}
                  {/* SHOW CUTOFF PRICES */}
                  <span className="text-2xl font-proxima-semibold text-red-500 line-through ml-2">
                    ₹
                    {parseFloat(props?.originalMonthlyPrice).toLocaleString(
                      "en-IN"
                    )}
                  </span>
                </h2>
              ) : (
                <h2 className="mt-5 text-5xl font-proxima-bold tracking-tight text-black leading-none">
                  ₹{parseFloat(props.monthlyPrice).toLocaleString("en-IN")}
                </h2>
              )
            ) : (
              <h2 className="mt-5 text-5xl font-proxima-bold tracking-tight text-black leading-none">
                ₹{parseFloat(props.annualPrice).toLocaleString("en-IN")}
                {/* SHOW CUTOFF PRICES */}
                <span className="text-2xl font-proxima-semibold text-red-500 line-through ml-2">
                  ₹
                  {parseFloat(props.originalAnnualPrice).toLocaleString(
                    "en-IN"
                  )}
                </span>
              </h2>
            )}
          </div>

          {props.isMonthly ? (
            <p className="text-sm font-proxima-regular text-grey mt-2">
              * billed monthly
            </p>
          ) : (
            <p className="text-sm font-proxima-regular text-grey mt-2">
              * billed annually
            </p>
          )}
        </div>

        {/* PLAN DESCRIPTION */}
        <div className="h-[7vh] mt-3">
          <p className="text-base font-proxima-regular text-grey">
            {props.description}
          </p>
        </div>
      </div>

      {/* FEATURES */}
      <div className="h-auto xl:h-[35vh] lg:h-[35vh] p-5 xl:p-8 lg:p-8">
        <h4 className="text-xl font-proxima-bold text-black">
          This plan includes:
        </h4>

        {props.features.map((item) => (
          <div
            className="flex items-center gap-3 mt-3 first:mt-0"
            key={item.id}
          >
            <FaCircleCheck className="w-5 h-5 text-logo" />
            <p className="text-base font-proxima-regular text-grey">
              {item.title}
            </p>
          </div>
        ))}
      </div>

      {/* WAITLIST BUTTON */}
      <div className="p-5 xl:p-8 lg:p-8">
        <Button
          className="w-full shadow-none hover:shadow-none bg-logo hover:bg-opacity-80 transition-all duration-300 ease-in-out rounded-md text-white text-base font-proxima-semibold normal-case"
          onClick={() => {
            toast.info(
              "We're currently not accepting new registrations. Thank you for your understanding!"
            );
          }}
        >
          Start Journaling
        </Button>
      </div>
    </div>
  );
};

export default PricingBox;
