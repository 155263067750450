const featureSegregation = [
  {
    id: 1,
    image:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/features/seamless-broker-integration.png",
    title: "Seamless Broker Integration",
    description:
      "Connect with leading brokers like Zerodha, Fyers, Dhan, and Upstox effortlessly. Our platform is designed to make your trading setup as straightforward as your morning routine, with robust security features to boot. Partner with us to simplify your trading process and focus on what really matters: your trades.",
    flatList: true,
    listHeader: "Brokers Available for Integration",
    footerLine: "More on the way!",
    list: [
      {
        id: 1,
        name: "Zerodha",
      },
      {
        id: 2,
        name: "Upstox",
      },
      {
        id: 3,
        name: "Dhan",
      },
      {
        id: 4,
        name: "Fyers",
      },
      {
        id: 5,
        name: "AngelOne",
      },
    ],
  },
  {
    id: 2,
    image:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/features/auto-data-sync.png",
    title: "Automated Data Synchronization",
    description:
      "Link your account and let us streamline your trading data. Our platform seamlessly imports details from executed trades to portfolio analytics, eliminating the need for manual entry. Focus on strategy, not number-crunching.",
    flatList: false,
    listHeader: "Data We Automatically Import",
    list: [
      {
        id: 1,
        name: "Order Book: Includes stock names, entries, exits, and quantities.",
      },
      {
        id: 2,
        name: "P&L Statements: Detailed trade-wise and total gross and net profits",
      },
      {
        id: 3,
        name: "Account Balances: Real-time funds available",
      },
    ],
  },
  {
    id: 3,
    image:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/features/metric-analysis.png",
    title: "Trading Metrics Analysis",
    description: "Explore crucial metrics to optimize your trading strategy",
    flatList: true,
    listHeader: "Key Metrics Include:",
    footerLine: "Many more!",
    list: [
      {
        id: 1,
        name: "Profit Factor",
      },
      {
        id: 2,
        name: "Win Rate",
      },
      {
        id: 3,
        name: "Daily Rate",
      },
      {
        id: 4,
        name: "Average Win/Loss Ratio",
      },
      {
        id: 5,
        name: "Trade expectancy",
      },
    ],
  },
  {
    id: 4,
    image:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/features/digital-trade-journal.png",
    title: "Digital Trade Journal",
    description:
      "Your trading data, neatly stacked for easy access. Add personal flair with notes, strategies, and even doodles, just kidding. It's like having a trading coach who doesn't mind your margin scribbles. Analyzing your moves is now as fun as making them!",
    flatList: true,
    listHeader: "",
    footerLine: "And a bunch more!",
    list: [
      {
        id: 1,
        name: "Daily & Trade Notes",
      },
      {
        id: 2,
        name: "Commission Tracking",
      },
      {
        id: 3,
        name: "Recording Setups & Mistakes",
      },
      {
        id: 4,
        name: "Assigning them to Individual Trades",
      },
      {
        id: 5,
        name: "Risk-Reward Ratio (RR) Tracking",
      },
    ],
  },
  {
    id: 5,
    image:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/features/in-depth-reports.png",
    title: "In-Depth Reports",
    description:
      "Meet your trading sidekick: our reports at TradeTalez. They're here to celebrate your wins and point out areas for improvement. No more drowning in spreadsheets; we turn your data into a clear performance snapshot. Whether you're a newbie or a seasoned pro, our reports give timely insights. Tracking progress has never been easier!",
    flatList: true,
    listHeader: "Performance based on:",
    list: [
      {
        id: 1,
        name: "Days",
      },
      {
        id: 2,
        name: "Weeks",
      },
      {
        id: 3,
        name: "Time",
      },
      {
        id: 4,
        name: "Duration",
      },
      {
        id: 5,
        name: "Setups",
      },
      {
        id: 6,
        name: "Mistakes",
      },
      {
        id: 7,
        name: "Risk-Reward Ratio (RR)",
      },
    ],
  },
  {
    id: 6,
    image:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/features/tt-insights.png",
    title: "TT insights: Your Trading Treasure Map",
    description:
      "Imagine TT insights as your trusted treasure map guide. While you navigate the trading waters with detailed reports, our insights show you exactly where the treasure lies. Tailored just for you, it enhances your trading journal, pointing out precisely what you need to sharpen your edge.",
    flatList: true,
    listHeader: "Insights showcase:",
    footerLine: "And much more.",
    list: [
      {
        id: 1,
        name: "Your best setups",
      },
      {
        id: 2,
        name: "Your biggest mistake",
      },
      {
        id: 3,
        name: "Your best time to trade",
      },
      {
        id: 4,
        name: "Your ideal trading day",
      },
      {
        id: 5,
        name: "Your behaviour as a bull and bea",
      },
      {
        id: 6,
        name: "Your behaviour as a Swing and Intraday trader",
      },
    ],
  },
];

export { featureSegregation };
