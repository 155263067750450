import React, { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";

/* USER DEFINED COMPONENTS */
import FrequentlyAskedQuestions from "components/faqs";
import Testimonials from "components/testimonials";

/* ANIMATION */
import { animation, containerAnimation } from "data/animation";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Card,
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";

/* ICONS */
import { FaCircleCheck } from "react-icons/fa6";
import { GoDash } from "react-icons/go";
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropdownCircle,
} from "react-icons/io";

/* DATA */
import { TABLE_HEAD, TABLE_ROWS } from "data/brokerSupport";

const BrokerSupport = () => {
  /* BROKER SUPPORT STATES */
  const [zerodhaAccordion, setZerodhaAccordion] = useState(false);
  const [angelAccordion, setAngelAccordion] = useState(false);
  const [dhanAccordion, setDhanAccordion] = useState(false);
  const [fyersAccordion, setFyersAccordion] = useState(false);
  const [upstoxAccordion, setUpstoxAccordion] = useState(false);
  const [motilalAccordion, setMotilalAccordion] = useState(false);

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white"
    >
      {/* CONTENT */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 relative"
      >
        <div className="container relative z-50">
          <div className="w-full xl:w-4/5 lg:w-4/5 mx-auto">
            {/* HEADING */}
            <div className="p-0">
              <h1 className="text-3xl xl:text-[5.5vh] lg:text-[5.5vh] font-helvetica-bold tracking-tight text-white text-center leading-snug">
                TradeTalez Broker Support
              </h1>
              <h2 className="text-lg xl:text-2xl lg:text-2xl font-proxima-regular text-white text-opacity-70 text-center mt-5">
                TradeTalez provides integration of stocks, options, and futures
                for all supported brokers in both CSV and auto-sync formats.
                Refer to the following table to check the integration provided
                for each broker.
              </h2>
            </div>

            {/* BROKER TABLE */}
            <Card className="w-full xl:w-3/4 lg:w-3/4 overflow-scroll lg:overflow-hidden mt-5 xl:mt-10 lg:mt-10 mx-auto border border-greyBorder">
              <table className="w-full table-auto text-left rounded-md">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head, index) => (
                      <th
                        key={head}
                        className={`bg-logo bg-opacity-40 border-b border-logo border-opacity-70 py-5 px-5 pr-16 xl:pr-10 lg:pr-10 xl:px-10 lg:px-10 ${
                          index === 0 && "rounded-tl-lg "
                        } ${
                          index === TABLE_HEAD.length - 1 && "rounded-tr-lg "
                        }`}
                      >
                        <h5
                          className={`text-xl font-proxima-bold text-black ${
                            index === 0 ? "text-left" : "text-center"
                          }`}
                        >
                          {head}
                        </h5>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWS.map(({ name, csv, autosync }, index) => {
                    const isLast = index === TABLE_ROWS.length - 1;
                    const classes = isLast
                      ? "py-5 px-5 pr-16 xl:pr-10 lg:pr-10 xl:px-10 lg:px-10"
                      : "py-5 px-5 pr-16 xl:pr-10 lg:pr-10 xl:px-10 lg:px-10 border-b border-greyBorder";

                    return (
                      <tr key={name}>
                        <td className="py-5 px-5 pr-0 xl:px-10 xl:pr-0 lg:pr-0 lg:px-10 border-b border-greyBorder col-span-2 w-auto xl:w-[45vh] lg:w-[45vh]">
                          {/* ZERODHA ACCORDION */}
                          {name === "Zerodha" && (
                            <Accordion
                              open={zerodhaAccordion}
                              className="group transition-all duration-300 ease-in-out w-[40vh]"
                            >
                              <AccordionHeader
                                className="text-lg text-black group-hover:text-logo p-0 border-b border-transparent font-proxima-semibold font-normal flex items-center justify-start gap-3 leading-none transition-all duration-300 ease-in-out"
                                onClick={() =>
                                  setZerodhaAccordion(!zerodhaAccordion)
                                }
                              >
                                {zerodhaAccordion ? (
                                  <IoMdArrowDropdownCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                ) : (
                                  <IoMdArrowDroprightCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                )}
                                {name}{" "}
                              </AccordionHeader>
                              <AccordionBody className="py-3">
                                <div className="ml-9">
                                  <p className="text-base font-proxima-regular text-grey">
                                    You need to sync your Zerodha trading
                                    account once in a day.
                                  </p>
                                  {/* <p className="text-base font-proxima-regular text-grey mt-2">
                                    <span className="text-red-500">*</span>{" "}
                                    Zerodha charges a separate ₹2,000 monthly
                                    fees for their API access.
                                  </p> */}
                                </div>
                              </AccordionBody>
                            </Accordion>
                          )}

                          {/* ANGEL ONE ACCORDION */}
                          {name === "AngelOne" && (
                            <Accordion
                              open={angelAccordion}
                              className="group transition-all duration-300 ease-in-out"
                            >
                              <AccordionHeader
                                className="text-lg text-black group-hover:text-logo p-0 border-b border-transparent font-proxima-semibold font-normal flex items-center justify-start gap-3 leading-none transition-all duration-300 ease-in-out"
                                onClick={() =>
                                  setAngelAccordion(!angelAccordion)
                                }
                              >
                                {angelAccordion ? (
                                  <IoMdArrowDropdownCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                ) : (
                                  <IoMdArrowDroprightCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                )}

                                {name}
                              </AccordionHeader>
                              <AccordionBody className="py-3">
                                <div className="ml-9">
                                  <p className="text-base font-proxima-regular text-grey">
                                    The access token will be updated
                                    automatically in the tool.
                                  </p>
                                </div>
                              </AccordionBody>
                            </Accordion>
                          )}

                          {/* DHAN ACCORDION */}
                          {name === "Dhan" && (
                            <Accordion
                              open={dhanAccordion}
                              className="group transition-all duration-300 ease-in-out"
                            >
                              <AccordionHeader
                                className="text-lg text-black group-hover:text-logo p-0 border-b border-transparent font-proxima-semibold font-normal flex items-center justify-start gap-3 leading-none transition-all duration-300 ease-in-out"
                                onClick={() => setDhanAccordion(!dhanAccordion)}
                              >
                                {dhanAccordion ? (
                                  <IoMdArrowDropdownCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                ) : (
                                  <IoMdArrowDroprightCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                )}

                                {name}
                              </AccordionHeader>
                              <AccordionBody className="py-3">
                                <div className="ml-9">
                                  <p className="text-base font-proxima-regular text-grey">
                                    You need to generate access token every 30
                                    days and add it to TradeTalez.
                                  </p>
                                </div>
                              </AccordionBody>
                            </Accordion>
                          )}

                          {/* FYERS ACCORDION */}
                          {name === "Fyers" && (
                            <Accordion
                              open={fyersAccordion}
                              className="group transition-all duration-300 ease-in-out"
                            >
                              <AccordionHeader
                                className="text-lg text-black group-hover:text-logo p-0 border-b border-transparent font-proxima-semibold font-normal flex items-center justify-start gap-3 leading-none transition-all duration-300 ease-in-out"
                                onClick={() =>
                                  setFyersAccordion(!fyersAccordion)
                                }
                              >
                                {fyersAccordion ? (
                                  <IoMdArrowDropdownCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                ) : (
                                  <IoMdArrowDroprightCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                )}

                                {name}
                              </AccordionHeader>
                              <AccordionBody className="py-3">
                                <div className="ml-9">
                                  <p className="text-base font-proxima-regular text-grey">
                                    You need to sync your Fyers trading account
                                    once in a day.
                                  </p>
                                </div>
                              </AccordionBody>
                            </Accordion>
                          )}

                          {/* FYERS ACCORDION */}
                          {name === "Motilal Oswal" && (
                            <Accordion
                              open={motilalAccordion}
                              className="group transition-all duration-300 ease-in-out"
                            >
                              <AccordionHeader
                                className="text-lg text-black group-hover:text-logo p-0 border-b border-transparent font-proxima-semibold font-normal flex items-center justify-start gap-3 leading-none transition-all duration-300 ease-in-out"
                                onClick={() =>
                                  setMotilalAccordion(!motilalAccordion)
                                }
                              >
                                {motilalAccordion ? (
                                  <IoMdArrowDropdownCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                ) : (
                                  <IoMdArrowDroprightCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                )}

                                {name}
                              </AccordionHeader>
                              <AccordionBody className="py-3">
                                <div className="ml-9">
                                  <p className="text-base font-proxima-regular text-grey">
                                    You need to sync your Motilal Oswal trading
                                    account once in a day.
                                  </p>
                                </div>
                              </AccordionBody>
                            </Accordion>
                          )}

                          {/* UPSTOX ACCORDION */}
                          {name === "Upstox" && (
                            <Accordion
                              open={upstoxAccordion}
                              className="group transition-all duration-300 ease-in-out"
                            >
                              <AccordionHeader
                                className="text-lg text-black group-hover:text-logo p-0 border-b border-transparent font-proxima-semibold font-normal flex items-center justify-start gap-3 leading-none transition-all duration-300 ease-in-out"
                                onClick={() =>
                                  setUpstoxAccordion(!upstoxAccordion)
                                }
                              >
                                {upstoxAccordion ? (
                                  <IoMdArrowDropdownCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                ) : (
                                  <IoMdArrowDroprightCircle className="w-6 h-6 text-grey group-hover:text-logo group-hover:text-opacity-60 text-opacity-60 transition-all duration-300 ease-in-out" />
                                )}

                                {name}
                              </AccordionHeader>
                              <AccordionBody className="py-3">
                                <div className="ml-9">
                                  <p className="text-base font-proxima-regular text-grey">
                                    You need to sync your Upstox trading account
                                    once in a day.
                                  </p>
                                </div>
                              </AccordionBody>
                            </Accordion>
                          )}
                        </td>
                        <td className={classes}>
                          <h5 className="text-xl font-proxima-regular text-center">
                            {csv ? (
                              <FaCircleCheck className="w-6 h-6 text-green-500 mx-auto" />
                            ) : (
                              <GoDash className="w-5 h-5 text-grey mx-auto" />
                            )}
                          </h5>
                        </td>
                        <td className={classes}>
                          <h5 className="text-xl font-proxima-regular text-center">
                            {autosync && (
                              <FaCircleCheck className="w-6 h-6 text-green-500 mx-auto" />
                            )}
                          </h5>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          </div>
        </div>

        {/* BACKGROUND GRADIENT */}
        <div className="newsletter-gradient h-[50vh] absolute top-0 left-0 w-full">
          {/* TOP RIGHT */}
          <div className="absolute top-0 right-0 overflow-hidden">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/banner-circle.webp"
              className="w-[15vh] xl:w-[27vh] lg:w-[27vh] h-auto banner-shape hidden xl:block lg:block"
              alt="Simplify, Analyze, and Optimize Your Trading Experience"
            />
          </div>

          {/* BOTTOM LEFT */}
          <div className="absolute bottom-0 left-0 overflow-hidden">
            <img
              src="https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/banner-circle.webp"
              className="w-[15vh] xl:w-[27vh] lg:w-[27vh] h-auto banner-shape-bottom hidden xl:block lg:block"
              alt="Simplify, Analyze, and Optimize Your Trading Experience"
            />
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <FrequentlyAskedQuestions />

      {/* TESTIMONIALS */}
      <Testimonials />
    </motion.section>
  );
};

export default BrokerSupport;
