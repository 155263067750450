import axios from "axios";
import request from "./request";
import { SERVER_URL } from "config";

/* CONTACT US API */
export const contactUsAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/contactUs`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* WAITLIST API */
export const waitingListAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/waitingList`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* REGISTER API */
export const registerAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`/auth/website/register`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const checkSubscription = (params,token) => {
  console.log('params,token: ', params,token);
  const request1 = axios.create({
    baseURL: SERVER_URL,
    timeout: 1000000,
  });
  request1.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `${token}`;
      // request1.push(config);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return new Promise(async (resolve, reject) => {
    await request1
      .get("/subscription/subscription-details", { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("e: ", e);
        reject(e);
      });
  });
};
// PROFILE
export const getProfile = (token) => {
  const request1 = axios.create({
    baseURL: SERVER_URL,
    timeout: 1000000,
  });
  request1.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `${token}`;
      // request1.push(config);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return new Promise(async (resolve, reject) => {
    await request1
      .get("/profile")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};