import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";

/* ANIMATION */
import { animation } from "data/animation";

/* ICONS */
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const FrequentlyAskedQuestions = () => {
  /* FAQ ACCORDION STATES */
  const [accordion1, setAccordion1] = useState(false);
  const [accordion2, setAccordion2] = useState(false);
  const [accordion3, setAccordion3] = useState(false);
  const [accordion4, setAccordion4] = useState(false);

  return (
    <motion.div
      variants={animation}
      className="md:px-4 lg:px-0 py-5 xl:py-14 lg:py-14 bg-[#f6f5f0]"
    >
      <div className="container">
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20">
          <div className="col-span-1">
            <h2 className="text-3xl xl:text-5xl lg:text-5xl font-helvetica-bold text-black tracking-tight leading-snug">
              Frequently Asked Questions
            </h2>
            <p className="mt-2 text-lg xl:text-xl lg:text-xl font-proxima-regular text-grey leading-snug">
              Explore answers to common queries about TradeTalez, your essential
              trading journal tool for effective trades management and analysis.
            </p>
          </div>
          <div className="col-span-1">
            <Accordion
              open={accordion1}
              icon={
                accordion1 ? (
                  <IoIosArrowUp className="w-4 h-4 text-black" />
                ) : (
                  <IoIosArrowDown className="w-4 h-4 text-black" />
                )
              }
            >
              <AccordionHeader
                className={`bg-white text-base font-proxima-semibold text-black border border-greyBorder ${
                  accordion1 ? "rounded-b-none rounded-t-lg" : "rounded-lg"
                } px-5 py-4`}
                onClick={() => setAccordion1(!accordion1)}
              >
                How do I record a trade in TradeTalez?
              </AccordionHeader>
              <AccordionBody className="bg-white rounded-b-lg p-5 border border-t-0 border-greyBorder">
                <p className="text-base font-proxima-regular text-greyText">
                  After logging in, navigate to the{" "}
                  <span className="font-proxima-semibold text-black">
                    ‘Import Trade’
                  </span>{" "}
                  section, you can either upload your Trade Book CSV or set up
                  auto sync with supported brokers.
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={accordion2}
              icon={
                accordion2 ? (
                  <IoIosArrowUp className="w-4 h-4 text-black" />
                ) : (
                  <IoIosArrowDown className="w-4 h-4 text-black" />
                )
              }
              className="mt-3"
            >
              <AccordionHeader
                className={`bg-white text-base font-proxima-semibold text-black border border-greyBorder ${
                  accordion2 ? "rounded-b-none rounded-t-lg" : "rounded-lg"
                } px-5 py-4`}
                onClick={() => setAccordion2(!accordion2)}
              >
                Can I import trades from my broker to TradeTalez?
              </AccordionHeader>
              <AccordionBody className="bg-white rounded-b-lg p-5 border border-t-0 border-greyBorder">
                <p className="text-base font-proxima-regular text-greyText">
                  Yes, TradeTalez supports importing trades from supported
                  brokers. Please refer to our{" "}
                  <span className="text-black font-proxima-semibold">
                    ‘Import Trades’
                  </span>{" "}
                  guide in our resource center for detailed instructions.
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={accordion3}
              icon={
                accordion3 ? (
                  <IoIosArrowUp className="w-4 h-4 text-black" />
                ) : (
                  <IoIosArrowDown className="w-4 h-4 text-black" />
                )
              }
              className="mt-3"
            >
              <AccordionHeader
                className={`bg-white text-base font-proxima-semibold text-black border border-greyBorder ${
                  accordion3 ? "rounded-b-none rounded-t-lg" : "rounded-lg"
                } px-5 py-4`}
                onClick={() => setAccordion3(!accordion3)}
              >
                What kind of analysis can I do with TradeTalez?
              </AccordionHeader>
              <AccordionBody className="bg-white rounded-b-lg p-5 border border-t-0 border-greyBorder">
                <p className="text-base font-proxima-regular text-greyText">
                  TradeTalez provides various analysis tools including
                  performance charts, win rate calculations, average risk/reward
                  ratios, and more.
                </p>
              </AccordionBody>
            </Accordion>

            <Accordion
              open={accordion4}
              icon={
                accordion4 ? (
                  <IoIosArrowUp className="w-4 h-4 text-black" />
                ) : (
                  <IoIosArrowDown className="w-4 h-4 text-black" />
                )
              }
              className="mt-3"
            >
              <AccordionHeader
                className={`bg-white text-base font-proxima-semibold text-black border border-greyBorder ${
                  accordion4 ? "rounded-b-none rounded-t-lg" : "rounded-lg"
                } px-5 py-4`}
                onClick={() => setAccordion4(!accordion4)}
              >
                Is my data secure with TradeTalez?
              </AccordionHeader>
              <AccordionBody className="bg-white rounded-b-lg p-5 border border-t-0 border-greyBorder">
                <p className="text-base font-proxima-regular text-greyText">
                  Yes, at TradeTalez we prioritise your data security. We use
                  industry-standard encryption methods to ensure your data is
                  safe and secure.
                </p>
              </AccordionBody>
            </Accordion>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default FrequentlyAskedQuestions;
