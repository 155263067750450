const testimonials = [
  {
    id: 1,
    name: "Himanshu Patel",
    designation: "Financial Analyst",
    profile:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/testimonial-01.jpg",
    testimonialText:
      "Trade Talez has transformed the way I manage my trades. As a financial analyst, I rely on data and insightful analysis. Trade Talez delivers on both fronts, allowing me to journal my trades efficiently and extract valuable insights.",
    date: "12 January, 2024",
  },
  {
    id: 2,
    name: "Rohit Patel",
    designation: "Day Trader",
    profile:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/testimonial-02.jpg",
    testimonialText:
      "Trade Talez has become my tool for journaling my trades and analyze performance. It is an indispensable asset in my trading toolkit.",
    date: "26 January, 2024",
  },
  {
    id: 3,
    name: "Jeet Sodha",
    designation: "Seasoned Investor",
    profile:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/jeet-sodha.jpg",
    testimonialText:
      "As a seasoned investor, I've tried numerous tools, but none compare to the simplicity and effectiveness of Trade Talez. ",
    date: "29 January, 2024",
  },
  {
    id: 4,
    name: "Darsh Shah",
    designation: "Novice Investor",
    profile:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/darsh-shah.png",
    testimonialText:
      "Trade Talez has simplified the daunting task of trade journaling for me. As a novice investor, I was overwhelmed by the complexities of tracking my trades. However, Trade Talez's intuitive platform has made the process seamless, allowing me to learn from my trades and improve my decision-making skills.",
    date: "01 February, 2024",
  },
  {
    id: 5,
    name: "Rajesh Menon",
    designation: "Portfolio Manager",
    profile:
      "https://storage.googleapis.com/cdn-roundtechsquare/trade-talez/testimonial-04.jpg",
    testimonialText:
      "Trade Talez has exceeded my expectations in every aspect. Its comprehensive features and customizable reports have enabled me, as a portfolio manager, to track the performance of my clients' investments with precision. It's a game-changer in the world of portfolio management.",
    date: "11 February, 2024",
  },
];

export default testimonials;
